import {
  getDataByUrl,
  postDataByUrl,
  updateDataByUrl,
  deleteDataByUrl,
  putDataByUrl,
  ID
} from 'requests';

export const getTeams = (data: any) => getDataByUrl(`/api/candidate/teams/`, data);

export const postTeams = (data: any) =>
  postDataByUrl(`/api/candidate/teams/`, data);

export const getTeam = (id: ID) => getDataByUrl(`/api/candidate/teams/${id}/`);

export const updateTeam = (id: ID, data: any) =>
  updateDataByUrl(`/api/candidate/teams/${id}/`, data);

export const putTeam = (id: ID, data: any) =>
  putDataByUrl(`/api/candidate/teams/${id}/`, data);

export const deleteTeam = (id: ID) =>
  deleteDataByUrl(`/api/candidate/teams/${id}/`);

export const postTeamMember = (id: ID, data: any) =>
  postDataByUrl(`/api/candidate/teams/${id}/add_candidate/`, data);

export const deleteTeamMember = (teamId: ID, data: any) =>
  postDataByUrl(`/api/candidate/teams/${teamId}/remove_candidate/`, data);