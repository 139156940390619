import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import PasswordPageContainer from 'components/containers/PasswordPageContainer';
import { ResetPasswordForm } from 'components/authentication/reset-password';
import { getButtons } from 'constants/text/buttons';
import { PATH_AUTH } from 'routes/paths';
import useLocales from 'hooks/useLocales';
import { Title8 } from 'components/text/title';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import HomeNavbar from 'layouts/home/HomeNavbar';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const { t } = useLocales();
  const { BACK } = getButtons();
  const { logo }: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  return (
    <PasswordPageContainer title={t('Verify New Password')}>
      <HomeNavbar logo={logo} />
      <Box sx={{ maxWidth: 480, mx: 'auto' }}>
        {!sent ? (
          <>
            <Title8 gutterBottom style={{ color: '#6C3C9D' }}>
              {t('Forgot your password?')}
            </Title8>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              {t('Please enter the email address associated with your account and. We will email you a link to reset your password')}
              .
            </Typography>

            <ResetPasswordForm
              onSent={() => setSent(true)}
              onGetEmail={(value) => setEmail(value)}
            />

            <ButtonPrimary
              style={{
                background: '#ffffff',
                color: '#666666',
                border: '1px solid #6C3C9D'
              }}
              fullWidth
              component={RouterLink}
              to={PATH_AUTH.login}
              sx={{ mt: 1 }}
            >
              {BACK}
            </ButtonPrimary>
          </>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              alt="sent email"
              src="/static/img/icons/ic_email_sent.svg"
              sx={{ mb: 5, mx: 'auto' }}
            />
            <Typography variant="h3" gutterBottom>
              {t('Request sent successfully')}
            </Typography>
            <Typography>
              {t('We have sent a confirmation email to')} &nbsp;
              <strong>{email}</strong>
              <br />
              {t('Please check your email.')}
            </Typography>

            <ButtonPrimary
              style={{
                background: '#ffffff',
                color: '#666666',
                border: '1px solid #6C3C9D'
              }}
              fullWidth
              component={RouterLink}
              to={PATH_AUTH.login}
              sx={{ mt: 5 }}
            >
              {BACK}
            </ButtonPrimary>
          </Box>
        )}
      </Box>
    </PasswordPageContainer>
  );
}
