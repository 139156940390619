import { Radar } from 'react-chartjs-2';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
  useTheme,
  Zoom
} from '@material-ui/core';

import { DoubleArrowRounded } from '@material-ui/icons';
import styled from '@emotion/styled';
import useLocales from 'hooks/useLocales';
import { getPlaceholders } from 'constants/text/placeholders';
import { getTexts } from 'constants/text/texts';
import {
  ApartmentOutlined as ApartmentOutlinedIcon,
  PermIdentityOutlined as PermIdentityOutlinedIcon
} from '@mui/icons-material';
import {
  MAX_COMPETENCY_SLIDER_RANGE,
  MIN_COMPETENCY_SLIDER_RANGE
} from '../constants/constants';
import EmptyContent from './EmptyContent';
import { ProgressBar } from './ProgressBar';
import { InfoButton } from './Buttons';
import { Description3 } from './text/title';
import { ScoresTooltip } from './ScoresTooltip';

interface label {
  name: string;
  id: number;
}

export interface sortedPoint {
  point?: number;
  label?: string;
  descriptions?: string;
  comparePoint?: number;
}

const purpleIconStyle = {
  height: '14px',
  color: 'rgb(86, 44, 130)',
  mr: '2px'
};

const blueIconStyle = {
  height: '14px',
  color: 'rgb(28, 157, 215)',
  mr: '2px'
};

const AccordionTitle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

const ButtonAccordionSummary = styled(AccordionSummary)(() => ({
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  boxShadow: '0px 0px 6.51128px rgba(0, 0, 0, 0.08)',
  borderRadius: '13px'
}));

const ButtonTypography = styled(Typography)`
  font-family: Rubik, serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff;
`;

const ButtonDoubleArrowRounded = styled(DoubleArrowRounded)(() => ({
  rotate: '90deg'
}));

const AccordionCard = styled(Accordion)`
  border-radius: 15px !important;

  &:before {
    display: none;
  }

  &:nth-child(2) {
    margin: 0 !important;

    .MuiButtonBase-root {
      min-height: 48px;
      height: 48px;
    }
  }
`;

export const ChartDescription = ({
  labels,
  color,
  accordionExpanded,
  style
}: any) => {
  const [expanded, setExpanded] = useState(accordionExpanded);
  const { DESCRIPTION } = getTexts();
  const { t } = useLocales();

  useEffect(() => {
    setExpanded(accordionExpanded);
  }, [accordionExpanded]);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  return labels.length ? (
    <AccordionCard style={style} expanded={expanded} onChange={handleChange}>
      <ButtonAccordionSummary
        expandIcon={<ButtonDoubleArrowRounded htmlColor="#FFFFFF" />}
        style={{
          background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`,
          height: accordionExpanded ? '64px' : '48px'
        }}
      >
        <AccordionTitle>
          <ButtonTypography variant="h6">{DESCRIPTION}</ButtonTypography>
        </AccordionTitle>
      </ButtonAccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
          my={2}
          pr="18px"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <ApartmentOutlinedIcon
              sx={{ ...purpleIconStyle, width: '14px', mr: '8px' }}
            />
            <Typography
              variant="caption"
              color="rgb(86, 44, 130)"
              fontWeight={600}
            >
              {t('Company')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PermIdentityOutlinedIcon sx={blueIconStyle} />
            <Typography
              variant="caption"
              color="rgb(28, 157, 215)"
              fontWeight={600}
            >
              {t('Candidate')}
            </Typography>
          </Box>
        </Box>
        {labels.map(
          ({ point, label, descriptions, comparePoint }: any, i: number) => (
            <div key={i}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Tooltip
                  arrow
                  placement="right"
                  TransitionComponent={Zoom}
                  title={descriptions || label}
                >
                  <Box sx={{ width: { xs: '100%', sm: '44%' } }}>
                    <Description3>{`${i + 1}. ${label}`}</Description3>
                  </Box>
                </Tooltip>
                <Box sx={{ width: { xs: '100%', sm: '55%' } }}>
                  <ProgressBar
                    max={MAX_COMPETENCY_SLIDER_RANGE}
                    value={point}
                    icon={<ApartmentOutlinedIcon sx={purpleIconStyle} />}
                  />
                  {comparePoint ? (
                    <ProgressBar
                      compared
                      max={MAX_COMPETENCY_SLIDER_RANGE}
                      value={comparePoint}
                      icon={<PermIdentityOutlinedIcon sx={blueIconStyle} />}
                    />
                  ) : null}
                </Box>
              </Box>
            </div>
          )
        )}
      </AccordionDetails>
    </AccordionCard>
  ) : null;
};

const CultureProfileData = styled(Box)(() => ({
  width: '100%',
  height: '100%'
}));

let XYIndexes: any = {};
const CultureProfileCharts = ({
  title,
  labels,
  points,
  compareTitle,
  comparePoints,
  style = {},
  boxSx = {},
  descriptions = null,
  withInfo = true,
  profile = false,
  color = '',
  fromPDF = false,
  scoresTooltipIsOpen,
  setScoresTooltipIsOpen
}: any) => {
  const canvasRef = useRef(null);
  const theme = useTheme();

  const [, setCreated] = useState<any>({});
  const { t } = useLocales();
  const { NO_DATA } = getPlaceholders();

  title = title || t('Competencies Score');
  let sortedPoints: Array<sortedPoint> = [];
  if (!labels?.[0]?.id) {
    sortedPoints =
      labels
        ?.map((el: string, i: number) => ({
          point: points[i],
          label: el,
          descriptions: profile
            ? descriptions[i]
            : descriptions.find((item: any) => item.name === el)?.description,
          comparePoint: comparePoints?.length ? comparePoints[i]?.score : []
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labels.sort((a: any, b: any) => b.id - a.id).reverse();
    const sortedInPoints = points
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPoints =
      sortedLabels
        ?.map((el: label, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: profile
            ? descriptions[i]
            : descriptions.find((item: any) => item.name === el.name)
                .description,
          comparePoint: comparePoints?.length ? comparePoints?.[i]?.score : []
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }

  if (sortedPoints && sortedPoints?.length) {
    const label: any = [];
    sortedPoints.map((el) => label.push(el.label));
    labels = label;
  }

  const titleCallback = (data: any) => {
    let text = '';
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      text += element.dataset.label[element.dataIndex];
      if (i === data.length - 1) {
        break;
      }
      text += '\n';
    }

    return text;
  };
  const labelCallback = (data: any) =>
    `${data.dataset.name === 'second' ? compareTitle : title}  ${
      data.formattedValue
    }`;

  const getColor = () => {
    if (color) {
      return `${color}40`;
    }
    return 'rgba(86, 44, 130, 0.3)';
  };

  const datasets = [
    {
      name: 'first',
      label: labels,
      data: sortedPoints.map((el: any) => el.point),
      backgroundColor: getColor(),
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      pointBackgroundColor: theme.palette.primary.main
    }
  ];

  if (comparePoints?.length && compareTitle) {
    datasets.push({
      name: 'second',
      label: labels,
      data: sortedPoints.map((el: any) => el.comparePoint),
      backgroundColor: 'rgba(28, 157, 215, 0.3)',
      borderColor: '#1C9DD7',
      borderWidth: 1.5,
      pointBackgroundColor: '#1C9DD7'
    });
  }
  const drawTextAtIndex = (scale: any, index: string, icon: any, text: any) => {
    const offset = 36;
    const r = scale.drawingArea + offset;
    const angle = scale.getIndexAngle(index) - Math.PI / 2;
    const x = scale.xCenter + Math.cos(angle) * r;
    const y = scale.yCenter + Math.sin(angle) * r;
    const { ctx } = scale;

    ctx.save();
    ctx.translate(x, y);
    ctx.textAlign = 'center';
    ctx.font = "600 10px 'Rubik'";
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = 'destination-over';

    const textArray = getLines(ctx, text, 75);
    let Xscore = 0;
    let Yscore = 0;
    if (index === '0') {
      ctx.fillText(`1 ${text}`, 0, 20);
      Xscore = -30;
      Yscore = -20;
    } else {
      textArray.map((item: string, i: number) => {
        let text = item;
        if (i === 0) {
          text = `${(Number(index) + 1).toString()} ${item}`;
        }
        ctx.fillText(
          text,
          labels.length / 2 < Number(index) ? -8 : 8,
          20 - textArray.length * 8 + i * 10
        );
        Xscore = labels.length / 2 < Number(index) ? -40 : -25;
        Yscore = 20 - textArray.length * 8 + i * 10 - 35 - textArray.length * 8;
      });
    }
    XYIndexes[index] = {
      x,
      y,
      scale,
      Xscore,
      Yscore,
      created: false
    };
    ctx.restore();
    if (canvasRef?.current && fromPDF) {
      const createdValue: any = {};
      mouseMove({ offsetX: 100, offsetY: 100 }, createdValue);
    }
  };

  const getLines = (ctx: any, text: any, maxWidth: any) => {
    const lines = [];
    if (text) {
      const words = text.split(' ');
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const { width } = ctx.measureText(`${currentLine} ${word}`);
        if (width < maxWidth) {
          currentLine += ` ${word}`;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
    }
    return lines;
  };

  const roundRect = (
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill = false,
    stroke = true
  ) => {
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  };

  const mouseMove = (e: any, createdValue: any) => {
    const valuesCanvas: any = Object.values(XYIndexes);
    for (let i = 0; i < valuesCanvas.length; i++) {
      if (!XYIndexes[i].created) {
        const element: any = valuesCanvas[i];
        const { x, y, scale } = element;
        const { ctx } = scale;
        const values = scale.chart.config.data.datasets[0].data;
        ctx.beginPath();
        ctx.strokeStyle = '#6666662b';
        ctx.fillStyle = '#ffffff';
        ctx.shadowBlur = 3;
        ctx.shadowColor = '#6666662b';
        roundRect(ctx, x + element.Xscore, y + element.Yscore, 70, 25, 5, true);
        ctx.beginPath();
        ctx.shadowBlur = 0;
        ctx.shadowColor = 'none';
        ctx.fillStyle = '#666666';
        ctx.font = "400 12px 'Rubik'";
        ctx.fillText(
          `${values[i]} score`,
          x + element.Xscore + 10,
          y + element.Yscore + 16
        );
        ctx.closePath();
        createdValue[`${i}`] = true;
        XYIndexes[i].created = true;
      }
    }
  };

  const tauch = () => {
    if (canvasRef?.current) {
      const { canvas }: any = canvasRef?.current as any;
      const createdValue: any = {};
      canvas.onmousemove = (e: any) => {
        const { offsetX, offsetY } = e;

        for (let i = 0; i < Object.values(XYIndexes).length; i++) {
          const element: any = Object.values(XYIndexes)[i];
          const { x, y, scale } = element;
          const { ctx } = scale;
          if (
            offsetX - x < 30 &&
            offsetX - x > -30 &&
            offsetY - y < 30 &&
            offsetY - y > -30 &&
            !createdValue[`${i}`]
          ) {
            let textArray = [];

            const width =
              canvas.clientWidth - 50 > 240 ? 240 : canvas.clientWidth - 50;
            if (sortedPoints)
              textArray = getLines(ctx, sortedPoints[i]?.descriptions, width);
            const textHeight = textArray.length * 13 + 30;
            ctx.beginPath();
            ctx.strokeStyle = '#6666662b';
            ctx.fillStyle = '#ffffff';
            ctx.shadowBlur = 3;
            ctx.shadowColor = '#6666662b';
            let xAxis = x;
            if (x + width > canvas.clientWidth && x - width > 0) {
              xAxis = x - width;
            } else if (x - width <= 0 && x + width > canvas.clientWidth) {
              xAxis = x - width / 2;
            }
            let yAxis = y;

            if (y + textHeight > canvas.clientHeight) {
              yAxis = y - textHeight;
            }

            roundRect(ctx, xAxis, yAxis, width + 40, textHeight, 20, true);
            ctx.beginPath();

            ctx.shadowBlur = 0;
            ctx.shadowColor = 'none';
            ctx.fillStyle = '#666666';
            ctx.font = "400 12px 'Rubik'";
            for (let j = 0; j < textArray.length; j++) {
              const element = textArray[j];
              ctx.fillText(element, xAxis + 20, yAxis + 25 + 13 * j);
            }
            ctx.closePath();
            createdValue[`${i}`] = true;
          } else if (
            createdValue[i] &&
            (offsetX - x >= 30 ||
              offsetX - x <= -30 ||
              offsetY - y >= 30 ||
              offsetY - y <= -30)
          ) {
            createdValue[`${i}`] = false;
            XYIndexes = {};
            setCreated({});
          }
        }
      };
    }
  };

  return (
    <>
      <Box sx={boxSx}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          pt="32px"
          mb="-8px"
          zIndex={100}
        >
          {withInfo && (
            <InfoButton
              onClick={() => {
                setScoresTooltipIsOpen(true);
              }}
            />
          )}
          <Tooltip
            arrow
            placement="right"
            TransitionComponent={Zoom}
            title={t('company_tooltip')}
          >
            <ApartmentOutlinedIcon
              sx={{
                ...purpleIconStyle,
                height: '24px',
                mr: 0
              }}
            />
          </Tooltip>
          <Tooltip
            arrow
            placement="right"
            TransitionComponent={Zoom}
            title={t('candidate_tooltip')}
          >
            <PermIdentityOutlinedIcon
              sx={{ ...blueIconStyle, height: '24px', mr: 0 }}
            />
          </Tooltip>
        </Box>
        <ScoresTooltip
          open={scoresTooltipIsOpen}
          onClose={() => setScoresTooltipIsOpen(false)}
        />
        <CultureProfileData
          sx={style}
          onMouseEnter={() => {
            XYIndexes = {};
            setCreated({});
            tauch();
          }}
          onMouseLeave={() => {
            XYIndexes = {};
            setCreated({});
          }}
        >
          {sortedPoints?.length ? (
            <Radar
              ref={canvasRef}
              data={{
                labels: labels.map((_: label) => '111111111111'),
                datasets
              }}
              options={{
                responsive: true,
                animation: {
                  duration: 0
                },
                scales: {
                  r: {
                    suggestedMin: MIN_COMPETENCY_SLIDER_RANGE,
                    suggestedMax: MAX_COMPETENCY_SLIDER_RANGE,
                    pointLabels: {
                      font: {
                        size: 14
                      },
                      color: 'transparent'
                    },
                    ticks: {
                      display: false,
                      maxTicksLimit: 6
                    },
                    grid: {
                      color: '#828282'
                    },
                    angleLines: {
                      color: '#828282'
                    }
                  }
                },
                plugins: {
                  datalabels: {
                    display: false
                  },
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      title: titleCallback,
                      label: labelCallback
                    },
                    titleFont: {
                      size: 16
                    },
                    bodyFont: {
                      size: 16
                    }
                  }
                }
              }}
              plugins={[
                {
                  id: 'custom_labels',
                  afterDraw: (chart: any) => {
                    const scale = chart.scales.r;
                    XYIndexes = {};
                    for (const key in sortedPoints) {
                      if (Object.prototype.hasOwnProperty.call(labels, key)) {
                        const element = sortedPoints[key]?.label;
                        if (element) {
                          drawTextAtIndex(scale, key, 'headset', element);
                        }
                      }
                    }
                  }
                }
              ]}
            />
          ) : (
            <EmptyContent title={NO_DATA} />
          )}
        </CultureProfileData>
      </Box>
    </>
  );
};

export default CultureProfileCharts;
