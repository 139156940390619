import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
  TextField,
  Card,
  Button
} from '@material-ui/core';
import {
  ArrowBackIosNew,
  KeyboardDoubleArrowDownOutlined as KeyboardDoubleArrowDownOutlinedIcon
} from '@mui/icons-material';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';

import {
  deleteTeamData,
  deleteTeamMemberFromTeam,
  getTeamDataByID,
  getTeamsData,
  updateTeamData
} from 'redux/slices/teams';
import { Controller, useForm } from 'react-hook-form';
import MButton from '@components/new-designer/button/MButton';
import { useSnackbar } from 'notistack';
import { RootState } from 'redux/store';
import { useDebounce } from '@hooks/useDebounce';
import LoadingBox from '@components/LoadingBox';
import { useNavigate, useParams } from 'react-router';
import Page from '@components/containers/Page';
import PageContainer from '@components/containers/PageContainer';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { getButtons } from 'constants/text/buttons';
import { PATH_DASHBOARD } from 'routes/paths';
import { CompetencyByGroupTypeNL } from 'types/Assessment';
import { AddTeamMemberButton } from '@components/AddTeamMemberButton';
import { Team, TeamCandidate, TeamShort } from '../../components/team/types';
import { TeamChart } from '../../components/team/TeamChart';
import {
  getGroupKeys,
  generateDatasetForGroup,
  downloadTeamPDF
} from '../../components/team/functions';
import { DeleteButton, EditButton } from '../../components/Buttons';

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 600,
  fontSize: '13px',
  color: '#131417',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '25px'
}));

const FilterWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'end',
  marginBottom: '16px'
}));

const HrDivider = styled(Divider)(() => ({
  height: 0,
  border: '0.6px solid #C9CAD9'
}));

const TeamPage = () => {
  const navigate = useNavigate();

  const { id } = useParams<any>();
  const teamId = Number(id);

  const [team, setTeam] = useState<Team>();
  const [isEditing, setIsEditing] = useState(false);
  const [datasetsByGroup, setDatasetsByGroup] = useState<
    { group: string; data: ReturnType<typeof generateDatasetForGroup> }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [chartRef, setChartRef] = useState<MutableRefObject<null>>();
  const [tableRef, setTableRef] = useState<MutableRefObject<null>>();
  const [targetGroup, setTargetGroup] = useState('');
  const [showTable, setShowTable] = useState(true);
  const teamMembersRef = useRef(null);

  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const teams = useSelector((state: RootState) => state.teams);

  const fetchedTeam = teams.team;

  const { BACK } = getButtons();

  const { user, isAdmin, isRecruiter, isRecruiterPlus } = useAuth();

  const canDeleteTeam =
    isAdmin ||
    isRecruiterPlus ||
    (isRecruiter && team?.user_name === user.name);

  const canEditTeam =
    isAdmin ||
    isRecruiterPlus ||
    (isRecruiter && team?.user_name === user.name);

  const getInitials = (name: string) => {
    const words = name.toUpperCase().split(' ');
    let initials = words[0][0];
    if (words.length > 1) {
      initials += words[words.length - 1][0];
    }
    return initials;
  };

  const {
    control,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors
  } = useForm({
    defaultValues: {
      teamName: '',
      prevTeamName: ''
    }
  });

  const teamName = watch('teamName');

  const prevTeamName = watch('prevTeamName');

  const debouncedValue = useDebounce(teamName, 500);

  const shortTeamsResults = teams.teamsShort?.results;

  const redirectToTeams = () => {
    navigate('/candidates/#teams');
    setTeam({
      candidates: [],
      created_at: '',
      id: 0,
      name: '',
      user_name: ''
    });
  };

  useEffect(() => {
    dispatch(getTeamDataByID(teamId));
  }, [dispatch, teamId, refresh]);

  useEffect(() => {
    if (fetchedTeam) {
      setTeam(fetchedTeam);
    }
  }, [fetchedTeam, dispatch, refresh]);

  useEffect(() => {
    if (team) {
      setValue('teamName', team?.name);
    }
  }, [team?.name]);

  useEffect(() => {
    if (isEditing) {
      dispatch(getTeamsData({ short: true, q: debouncedValue }));
    }
  }, [isEditing]);

  const handleDeleteMember = async (teamId: number, candidateId: number) => {
    dispatch(deleteTeamMemberFromTeam(teamId, candidateId));
    if (team?.candidates.length === 1) {
      redirectToTeams();
    }
  };

  const handleDeleteTeam = (teamId: number) => {
    dispatch(deleteTeamData(teamId));
    redirectToTeams();
  };

  // Dynamically get group keys from the data

  const groupKeys = useMemo(
    () => getGroupKeys(team?.candidates || []),
    [team?.candidates]
  );

  // Generate datasets for each group key

  useEffect(() => {
    const fetchDatasets = async () => {
      setLoading(true);
      try {
        const newDatasets = await Promise.all(
          groupKeys.map(async (groupKey) => {
            if (team?.candidates && Array.isArray(team?.candidates)) {
              return {
                group: groupKey,
                data: await generateDatasetForGroup(
                  team?.candidates,
                  groupKey as keyof CompetencyByGroupTypeNL
                )
              };
            }
            return null;
          })
        );
        setDatasetsByGroup(
          newDatasets.filter(Boolean) as typeof datasetsByGroup
        );
      } catch (error) {
        console.error('Error generating datasets:', error);
      }
      setLoading(false);
    };

    fetchDatasets();
  }, [groupKeys, team?.candidates]);

  const dataSet = Array.from(datasetsByGroup) || [];

  const targetDataSet = dataSet.find(
    (data) => data?.group && data.group === targetGroup
  );

  const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTargetGroup(event.target.value as string);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    clearErrors();
  };

  const handleUpdateTeamName = () => {
    if (team && teamName) {
      if (
        team?.name !== teamName &&
        shortTeamsResults?.some((res: TeamShort) => res.name === teamName)
      ) {
        setError('teamName', {
          type: 'manual',
          message: t('Team with this name already exists')
        });
        enqueueSnackbar(t('Team with this name already exists'), {
          variant: 'error'
        });
        return;
      }

      const data = { name: teamName };
      try {
        dispatch(updateTeamData(team.id, data));
        cancelEdit();
        // setRefresh((prevState: any) => !prevState);
        enqueueSnackbar(t(`Successfully created ${teamName}`), {
          variant: 'success'
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('Something went wrong, please try again later'), {
          variant: 'error'
        });
      }
    } else {
      setError('teamName', {
        type: 'manual',
        message: 'Team name is required'
      });
    }
  };

  useEffect(() => {
    setTargetGroup(groupKeys[0]);
  }, [groupKeys]);

  const handleDownloadTeamPDF = async () => {
    await downloadTeamPDF([chartRef, tableRef, teamMembersRef]);
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  const hasCompetencies =
    fetchedTeam?.candidates &&
    fetchedTeam?.candidates.some(
      (candidate: TeamCandidate) => candidate?.competencies
    );

  return (
    <Page title={`${t('Team')}: ${teamName}`}>
      <PageContainer>
        <DashboardNavbar title={teamName} />
        <Button
          onClick={redirectToTeams}
          variant="text"
          style={{
            color: '#562C82',
            marginBottom: '20px'
          }}
        >
          <ArrowBackIosNew
            style={{
              fontSize: '18px',
              margin: '0 0 5px 0'
            }}
          />
          {BACK}
        </Button>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              height: '100%',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            {/* title */}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                {isEditing && canEditTeam ? (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px'
                      }}
                    >
                      <Controller
                        name="teamName"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Team name is required' }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            placeholder={t('Team name')}
                            style={{
                              width: '100%'
                            }}
                          />
                        )}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '56px',
                          gap: '8px'
                        }}
                      >
                        <MButton
                          title={t('Submit')}
                          MyClass="violetButton"
                          click={handleUpdateTeamName}
                        />
                        <MButton
                          title={t('Cancel')}
                          MyClass="violetOutlined"
                          click={() => {
                            cancelEdit();
                            setValue('teamName', prevTeamName);
                          }}
                        />
                      </Box>
                    </Box>
                    {errors.teamName && (
                      <Box
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginTop: '8px'
                        }}
                      >
                        <Typography variant="caption" color="error">
                          {errors.teamName?.message?.toString()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TitleTypography
                      variant="h4"
                      style={{ textOverflow: 'ellipsis' }}
                    >
                      {teamName}
                    </TitleTypography>
                  </Box>
                )}

                <Box display="flex" gap={1} minWidth="400px">
                  <AddTeamMemberButton
                    withTeamSelector
                    team={team}
                    setRefresh={setRefresh}
                    style={{ width: '100%' }}
                  />
                  <MButton
                    title={t('Download PDF')}
                    MyClass="violetButton"
                    click={() => {
                      setShowTable(true);
                      handleDownloadTeamPDF();
                    }}
                    style={{ width: '100%' }}
                  />
                  {canEditTeam && (
                    <EditButton
                      onClick={() => {
                        if (isEditing) {
                          cancelEdit();
                        } else {
                          setIsEditing(true);
                          setValue('prevTeamName', teamName);
                        }
                      }}
                    />
                  )}
                  {canDeleteTeam && team?.id && (
                    <DeleteButton
                      dialogText={`${t(`Are you sure you want to delete`)} ${teamName} ?`}
                      onClick={() => handleDeleteTeam(team.id)}
                    />
                  )}
                </Box>
              </Box>
              <HrDivider sx={{ width: '100%' }} />
              <Box display="flex" width="100%" justifyContent="space-between">
                {team?.user_name && (
                  <Typography variant="caption" color="GrayText" mt={1}>
                    {t(`Created by ${team?.user_name}`)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Grid
              container
              spacing={3}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex'
              }}
            >
              {/* left side */}
              <Grid item xs={12} md={9}>
                {hasCompetencies ? (
                  <Grid item xs={12}>
                    <FilterWrapper>
                      <Select
                        value={targetGroup}
                        onChange={handleGroupChange}
                        displayEmpty
                        renderValue={(selected) =>
                          selected || (
                            <span style={{ color: '#aaa' }}>
                              {t('Select a Group')}
                            </span>
                          )
                        }
                        sx={{ minWidth: '180px' }}
                      >
                        <MenuItem value="" disabled>
                          {t('Select Group')}
                        </MenuItem>
                        {groupKeys.map((key) => (
                          <MenuItem key={key} value={key}>
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FilterWrapper>
                    {loading ? (
                      <LoadingBox />
                    ) : (
                      targetDataSet && (
                        <TeamChart
                          showTable={showTable}
                          setShowTable={setShowTable}
                          setChartRef={setChartRef}
                          setTableRef={setTableRef}
                          datasetsByGroup={[targetDataSet]}
                          labels={targetDataSet.data.labels}
                          showLegend
                          withTable
                        />
                      )
                    )}
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <TitleTypography
                      variant="h6"
                      style={{ textOverflow: 'ellipsis' }}
                      my="12px"
                    >
                      {t('Non of the team members have competency scores')}
                    </TitleTypography>
                    <Typography variant="caption">
                      {t('Add a candidate to the team who has taken assessment test to see their competency scores and graphs')}
                    </Typography>
                  </Box>
                )}
              </Grid>

              {/* right side */}
              <Grid ref={teamMembersRef} item xs={12} md={3}>
                <Grid sx={{ height: '50%' }}>
                  {team?.candidates ? (
                    <Box mt="24px" width="100%">
                      <TitleTypography
                        variant="h6"
                        style={{ textOverflow: 'ellipsis' }}
                        mb="12px"
                        ml="16px"
                      >
                        {t('Team members')}
                      </TitleTypography>
                      <List
                        sx={{
                          overflowY: 'auto',
                          maxHeight: '780px'
                        }}
                      >
                        {team?.candidates.map((candidate: TeamCandidate) => (
                          <ListItem
                            key={candidate.id}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <Button
                              onClick={() =>
                                navigate(
                                  PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                                    candidate.id
                                  )
                                )
                              }
                              sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start'
                              }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                sx={{ width: '40px', height: '40px', mr: 1 }}
                              >
                                {getInitials(candidate.name)}
                              </Avatar>
                              <Typography variant="body2">
                                {candidate.name}
                              </Typography>
                            </Button>
                            <DeleteButton
                              dialogText={`${t('Are you sure you want to remove')} ${candidate.name} ${t('from')} ${team?.name}?`}
                              onClick={() =>
                                handleDeleteMember(team?.id, candidate.id)
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                      {team?.candidates.length > 12 && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <KeyboardDoubleArrowDownOutlinedIcon />
                          <Typography variant="caption">
                            {t('Scroll for more')}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </PageContainer>
    </Page>
  );
};

export default TeamPage;
