import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import useLocales from '@hooks/useLocales';
import { Box, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam, getTeamsData } from 'redux/slices/teams';
import { useDebounce } from '@hooks/useDebounce';
import { RootState } from 'redux/store';
import { useSnackbar } from 'notistack';
import { CustomModal } from './CustomModal';
import MButton from './new-designer/button/MButton';

export interface TeamShort {
  candidates: number[];
  id: number;
  name: string;
  user_name: string;
}

export const AddTeamButton = ({
  setRefresh
}: {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    watch,
    setError,
    reset
  } = useForm({
    defaultValues: {
      teamName: ''
    }
  });

  const teamName = watch('teamName');

  const debouncedValue = useDebounce(teamName, 500);

  const teams = useSelector((state: RootState) => state.teams);

  const shortTeamsResults = teams.teamsShort?.results;

  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const toggleDialog = () => {
    setDialogOpen((prev) => !prev);
    reset();
  };

  useEffect(() => {
    if (isDialogOpen) {
      dispatch(getTeamsData({ short: true, q: debouncedValue }));
    }
  }, [dispatch, debouncedValue]);

  const handleCreateTeam = () => {
    if (teamName) {
      if (shortTeamsResults?.some((res: TeamShort) => res.name === teamName)) {
        setError('teamName', {
          type: 'manual',
          message: t('Team with this name already exists')
        });
        enqueueSnackbar(t('Team with this name already exists'), {
          variant: 'error'
        });
        return;
      }
      try {
        dispatch(createTeam(teamName));
        toggleDialog();
        setRefresh((prevState) => !prevState);
        enqueueSnackbar(t(`Successfully created ${teamName}`), {
          variant: 'success'
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('Something went wrong, please try again later'), {
          variant: 'error'
        });
      }
    } else {
      setError('teamName', {
        type: 'manual',
        message: 'Team name is required'
      });
    }
  };

  return (
    <>
      <MButton
        title={t('Create Team')}
        MyClass="violetButton"
        click={toggleDialog}
        style={{ width: 'fit-content' }}
      />

      <CustomModal open={isDialogOpen} onClose={toggleDialog}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" fontWeight={600} mb="8px">
            {t('Enter your team name here')}
          </Typography>

          <Controller
            name="teamName"
            control={control}
            defaultValue=""
            rules={{ required: 'Team name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                placeholder={t('Team name')}
                style={{
                  width: '100%',
                  marginBottom: errors.teamName ? '8px' : '24px'
                }}
              />
            )}
          />
          {errors.teamName && (
            <Box
              style={{ display: 'flex', width: '100%', marginBottom: '24px' }}
            >
              <Typography variant="caption" color="error">
                {errors.teamName?.message?.toString()}
              </Typography>
            </Box>
          )}

          <MButton
            title={t('Create Team')}
            MyClass="violetButton"
            click={handleCreateTeam}
            style={{
              width: '100%'
            }}
          />
        </Box>
      </CustomModal>
    </>
  );
};
