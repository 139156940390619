import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getJobPostData,
  getApplicantsData,
  getTodosData
} from 'redux/slices/showcase';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import PageContainer from '../../components/containers/PageContainer';
import CampaignsOverview from './CampaignsOverview';
import JobPostsMostViewed from './JobPostsMostViewed';
import JobPost from './JobPost';
import JobPostsOverview from './JobPostsOverview';
import ApplicantsOverview from './ApplicantsOverview';

const applicantsOverview = '/static/img/icons/applicantsOverview.svg';
const applicantsOverviewActive = '/static/img/icons/applicantsOverviewActive.svg';
const campaign = '/static/img/icons/campaign.svg';
const campaignActive = '/static/img/icons/campaignActive.svg';
const jobPost = '/static/img/icons/jobPost.svg';
const jobPostActive = '/static/img/icons/jobPostActive.svg';
const mostViewedJobPosts = '/static/img/icons/mostViewedJobPosts.svg';
const mostViewedJobPostsActive = '/static/img/icons/mostViewedJobPostsActive.svg';
const candidateApplicantsOverview = '/static/img/icons/applicationOverview.svg';
const candidateApplicantsOverviewActive =
  '/static/img/icons/applicationOverviewActive.svg';

export default function Dashboard() {
  const { t } = useLocales();
  const {
    userId,
    isAdmin,
    isRecruiterPlus,
    isRecruiter,
    isHiringManager,
    isDesigner,
    isCandidate
  } = useAuth();
  const canSeeTodos =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager || isDesigner;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && !isDesigner) {
      dispatch(getJobPostData());
      dispatch(getApplicantsData());
    }
    if (canSeeTodos) {
      dispatch(getTodosData());
    }
  }, [dispatch, isDesigner, canSeeTodos]);

  const canCreateNewJobPost = isAdmin || isRecruiterPlus || isRecruiter;

  const canAccessCampaigns =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  const tabs = [
    {
      label: isCandidate ? t('Jobs') : t('Job Posts Overview'),
      Component: <JobPostsOverview />,
      icon: applicantsOverview,
      activeIcon: applicantsOverviewActive,
      path: 'all'
    },
    {
      label: t('Create New Job Post'),
      Component: <JobPost />,
      icon: jobPost,
      activeIcon: jobPostActive,
      path: 'new',
      hide: !canCreateNewJobPost
    },
    {
      label: t('Most Viewed Job Posts'),
      Component: <JobPostsMostViewed />,
      icon: mostViewedJobPosts,
      activeIcon: mostViewedJobPostsActive,
      path: 'most-viewed',
      subTitle: t('These are your most viewed job posts'),
      hide: isCandidate
    },
    // only for candidates
    {
      label: t('My Applications'),
      Component: <ApplicantsOverview style={{ padding: 0 }} />,
      icon: candidateApplicantsOverview,
      activeIcon: candidateApplicantsOverviewActive,
      hide: !isCandidate,
      path: 'applications',
      subTitle: t('Your selection of candidates for your jobs, go ahead and make someone happy today!')
    },
    {
      label: t('My Campaigns'),
      Component: <CampaignsOverview />,
      icon: campaign,
      activeIcon: campaignActive,
      path: 'campaigns',
      subTitle: t('Here you can view your job marketing campaigns'),
      hide: !canAccessCampaigns
    }
  ];

  return (
    <Page>
      <PageContainer>
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
}
