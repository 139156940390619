import JsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { CompetencyByGroupTypeNL } from 'types/Assessment';
import { TeamCandidate } from './types';

export const getGroupKeys = (candidates: TeamCandidate[]): string[] => {
  if (!candidates || candidates.length === 0) return [];

  // Find the first candidate with competencies
  const candidateWithCompetencies = candidates.find(
    (candidate) =>
      candidate.competencies && Object.keys(candidate.competencies).length > 0
  );

  // If a candidate with competencies is found, return the keys
  if (candidateWithCompetencies && candidateWithCompetencies.competencies) {
    return Object.keys(candidateWithCompetencies.competencies);
  }

  // Otherwise, return an empty array
  return [];
};

export const generateDatasetForGroup = (
  candidates: TeamCandidate[],
  groupKey: keyof CompetencyByGroupTypeNL
) => {
  const labels = [] as string[];
  const comparePoints: number[][] = [];
  const candidateNames: string[] = [];
  const descriptions: string[] = [];

  candidates.forEach((candidate: TeamCandidate) => {
    if (candidate.competencies) {
      const group = (candidate.competencies as CompetencyByGroupTypeNL)[
        groupKey
      ];

      if (group && Array.isArray(group.lables) && Array.isArray(group.data)) {
        candidateNames.push(candidate.name);
        group.lables.forEach((label: string, index: number) => {
          if (!labels.includes(label)) {
            labels.push(label);
            descriptions.push(group.descriptions[index]);
          }
          comparePoints[index] = comparePoints[index] || [];
          comparePoints[index].push(group.data[index]);
        });
      }
      // else {
      //         console.warn(
      //           `Invalid group structure for candidate:`,
      //           candidate,
      //           `groupKey: ${groupKey}`
      //         );
      //       }
    }
    // else {
    //       console.warn(`Candidate has no competencies:`, candidate);
    //     }
  });

  // Calculate average points or other aggregated metrics
  const points = comparePoints.map(
    (values) => values.reduce((sum, val) => sum + val, 0) / values.length
  );

  return { points, labels, comparePoints, candidateNames, descriptions };
};

export const downloadTeamPDF = async (
  refs: (React.MutableRefObject<null> | undefined)[]
) => {
  const pdf = new JsPDF('p', 'mm', 'a4');
  const pageWidth = 210; // A4 width in mm
  const pageHeight = 297; // A4 height in mm

  if (refs) {
    const captureImages = await Promise.all(
      refs.map(async (ref, i) => {
        const element = ref?.current as HTMLDivElement | null;
        if (!element) {
          console.warn(`No valid element for ref ${i + 1}`);
          return null;
        }

        const originalHeight = element.style.height;
        const originalOverflow = element.style.overflow;

        element.style.height = 'auto';
        element.style.overflow = 'visible';

        try {
          const dataUrl = await domtoimage.toPng(element);
          let imgWidth = pageWidth;
          let imgHeight =
            (imgWidth * element.offsetHeight) / element.offsetWidth;

          if (imgHeight > pageHeight) {
            imgHeight = pageHeight;
            imgWidth = (element.offsetWidth * imgHeight) / element.offsetHeight;
          }

          return { dataUrl, imgWidth, imgHeight };
        } catch (error) {
          console.error(`Error capturing element ${i + 1}:`, error);
          return null;
        } finally {
          element.style.height = originalHeight;
          element.style.overflow = originalOverflow;
        }
      })
    );

    captureImages.forEach((image, i) => {
      if (!image) return;

      if (i > 0) pdf.addPage();

      pdf.addImage(
        image.dataUrl,
        'PNG',
        0,
        0,
        image.imgWidth,
        image.imgHeight,
        '',
        'MEDIUM'
      );
    });

    pdf.save('team-modal.pdf');
  }
};



// const drawTextAtIndex = (scale: any, index: string, icon: any, text: any) => {
//   const offset = 36;
//   const r = scale.drawingArea + offset;
//   const angle = scale.getIndexAngle(index) - Math.PI / 2;
//   const x = scale.xCenter + Math.cos(angle) * r;
//   const y = scale.yCenter + Math.sin(angle) * r;
//   const { ctx } = scale;

//   ctx.save();
//   ctx.translate(x, y);
//   ctx.textAlign = 'center';
//   ctx.font = "600 10px 'Rubik'";
//   ctx.fillStyle = 'black';
//   ctx.globalCompositeOperation = 'destination-over';

//   const textArray = getLines(ctx, text, 75);
//   let Xscore = 0;
//   let Yscore = 0;
//   if (index === '0') {
//     ctx.fillText(`1 ${text}`, 0, 20);
//     Xscore = -30;
//     Yscore = -20;
//   } else {
//     textArray.map((item: string, i: number) => {
//       let text = item;
//       if (i === 0) {
//         text = `${(Number(index) + 1).toString()} ${item}`;
//       }
//       ctx.fillText(
//         text,
//         labels.length / 2 < Number(index) ? -8 : 8,
//         20 - textArray.length * 8 + i * 10
//       );
//       Xscore = labels.length / 2 < Number(index) ? -40 : -25;
//       Yscore = 20 - textArray.length * 8 + i * 10 - 35 - textArray.length * 8;
//     });
//   }
//   XYIndexes[index] = {
//     x,
//     y,
//     scale,
//     Xscore,
//     Yscore,
//     created: false
//   };
//   ctx.restore();
//   if (canvasRef?.current) {
//     const createdValue: any = {};
//     mouseMove({ offsetX: 100, offsetY: 100 }, createdValue);
//   }
// };

// const getLines = (ctx: any, text: any, maxWidth: any) => {
//   const lines = [];
//   if (text) {
//     const words = text.split(' ');
//     let currentLine = words[0];

//     for (let i = 1; i < words.length; i++) {
//       const word = words[i];
//       const { width } = ctx.measureText(`${currentLine} ${word}`);
//       if (width < maxWidth) {
//         currentLine += ` ${word}`;
//       } else {
//         lines.push(currentLine);
//         currentLine = word;
//       }
//     }
//     lines.push(currentLine);
//   }
//   return lines;
// };

// const roundRect = (
//   ctx: any,
//   x: any,
//   y: any,
//   width: any,
//   height: any,
//   radius: any = 5,
//   fill = false,
//   stroke = true
// ) => {
//   if (typeof radius === 'number') {
//     radius = { tl: radius, tr: radius, br: radius, bl: radius };
//   } else {
//     radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
//   }
//   ctx.beginPath();
//   ctx.moveTo(x + radius.tl, y);
//   ctx.lineTo(x + width - radius.tr, y);
//   ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
//   ctx.lineTo(x + width, y + height - radius.br);
//   ctx.quadraticCurveTo(
//     x + width,
//     y + height,
//     x + width - radius.br,
//     y + height
//   );
//   ctx.lineTo(x + radius.bl, y + height);
//   ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
//   ctx.lineTo(x, y + radius.tl);
//   ctx.quadraticCurveTo(x, y, x + radius.tl, y);
//   ctx.closePath();
//   if (fill) {
//     ctx.fill();
//   }
//   if (stroke) {
//     ctx.stroke();
//   }
// };

// const mouseMove = (e: any, createdValue: any) => {
//   const valuesCanvas: any = Object.values(XYIndexes);
//   for (let i = 0; i < valuesCanvas.length; i++) {
//     if (!XYIndexes[i].created) {
//       const element: any = valuesCanvas[i];
//       const { x, y, scale } = element;
//       const { ctx } = scale;
//       const values = scale.chart.config.data.datasets[0].data;
//       ctx.beginPath();
//       ctx.strokeStyle = '#6666662b';
//       ctx.fillStyle = '#ffffff';
//       ctx.shadowBlur = 3;
//       ctx.shadowColor = '#6666662b';
//       roundRect(ctx, x + element.Xscore, y + element.Yscore, 70, 25, 5, true);
//       ctx.beginPath();
//       ctx.shadowBlur = 0;
//       ctx.shadowColor = 'none';
//       ctx.fillStyle = '#666666';
//       ctx.font = "400 12px 'Rubik'";
//       ctx.fillText(
//         `${values[i]} score`,
//         x + element.Xscore + 10,
//         y + element.Yscore + 16
//       );
//       ctx.closePath();
//       createdValue[`${i}`] = true;
//       XYIndexes[i].created = true;
//     }
//   }
// };

// const tauch = (setCreated) => {
//   const { canvas }: any = canvasRef.current as any;
//   const createdValue: any = {};
//   canvas.onmousemove = (e: any) => {
//     const { offsetX, offsetY } = e;

//     for (let i = 0; i < Object.values(XYIndexes).length; i++) {
//       const element: any = Object.values(XYIndexes)[i];
//       const { x, y, scale } = element;
//       const { ctx } = scale;
//       if (
//         offsetX - x < 30 &&
//         offsetX - x > -30 &&
//         offsetY - y < 30 &&
//         offsetY - y > -30 &&
//         !createdValue[`${i}`]
//       ) {
//         const textArray = [];

//         const width =
//           canvas.clientWidth - 50 > 240 ? 240 : canvas.clientWidth - 50;
//         // if (sortedPoints)
//         //   textArray = getLines(ctx, sortedPoints[i]?.descriptions, width);
//         const textHeight = textArray.length * 13 + 30;
//         ctx.beginPath();
//         ctx.strokeStyle = '#6666662b';
//         ctx.fillStyle = '#ffffff';
//         ctx.shadowBlur = 3;
//         ctx.shadowColor = '#6666662b';
//         let xAxis = x;
//         if (x + width > canvas.clientWidth && x - width > 0) {
//           xAxis = x - width;
//         } else if (x - width <= 0 && x + width > canvas.clientWidth) {
//           xAxis = x - width / 2;
//         }
//         let yAxis = y;

//         if (y + textHeight > canvas.clientHeight) {
//           yAxis = y - textHeight;
//         }

//         roundRect(ctx, xAxis, yAxis, width + 10, textHeight, 20, true);
//         ctx.beginPath();

//         ctx.shadowBlur = 0;
//         ctx.shadowColor = 'none';
//         ctx.fillStyle = '#666666';
//         ctx.font = "400 12px 'Rubik'";
//         for (let j = 0; j < textArray.length; j++) {
//           const element = textArray[j];
//           ctx.fillText(element, xAxis + 20, yAxis + 25 + 13 * j);
//         }
//         ctx.closePath();
//         createdValue[`${i}`] = true;
//       } else if (
//         createdValue[i] &&
//         (offsetX - x >= 30 ||
//           offsetX - x <= -30 ||
//           offsetY - y >= 30 ||
//           offsetY - y <= -30)
//       ) {
//         createdValue[`${i}`] = false;
//         XYIndexes = {};
//         setCreated({});
//       }
//     }
//   };
// };
