import ListContainer from '@components/containers/ListContainer';
import HeadingContainer from '@components/containers/HeadingContainer';
import Page from '@components/containers/Page';
import PageContainer from '@components/containers/PageContainer';
import useLocales from '@hooks/useLocales';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { useEffect, useState } from 'react';
import PaginationContainer from '@components/containers/PaginationContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setTeamsFilter } from 'redux/slices/filters';
import { TeamCard } from '@components/team/TeamCard';
import { getTeamsData } from 'redux/slices/teams';
import { AddTeamButton } from '@components/AddTeamButton';
import useAuth from '@hooks/useAuth';

const Teams = ({ style = {} }) => {
  const [teamsList, setTeamsList] = useState();
  const [refresh, setRefresh] = useState(false);

  const { t } = useLocales();
  const dispatch = useDispatch();
  const { isAdmin, isRecruiterPlus, isRecruiter } = useAuth();
  const teams = useSelector((state: RootState) => state.teams);
  const filters = useSelector((state: RootState) => state.filtersReducer.teams);

  const { page, limit, offset } = filters;
  const { results, count } = teams.response;

// console.log("results:",results);

  useEffect(() => {
    dispatch(getTeamsData({ limit, offset }));
  }, [dispatch, limit, offset, refresh]);

  useEffect(() => {
    setTeamsList(results);
  }, [results, refresh]);

  const canAddTeam = isAdmin || isRecruiterPlus || isRecruiter;

  const title = t('Teams');
  return (
    <Page title={title}>
      <DashboardNavbar title={title} />
      <PageContainer style={style}>
        <HeadingContainer
          subtitle={t('')}
          actionButton={canAddTeam && <AddTeamButton setRefresh={setRefresh} />}
        />
        <ListContainer
          list={teamsList}
          card={TeamCard}
          getCardProps={() => ({
            type: 'match',
            setRefresh
          })}
          gridProps={{ sm: 6, lg: 4 }}
        />
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => {
            dispatch(setTeamsFilter('page', value));
          }}
        />
      </PageContainer>
    </Page>
  );
};

export default Teams;