import { CSSProperties, ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';

import { EditButton, UploadButton } from '../Buttons';
import { SortedGroupsListType, SortedGroupsType } from 'types/Assessment';
import { MAX_COMPETENCY_SLIDER_RANGE } from 'constants/constants';
import { ProgressBar } from 'components/ProgressBar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Title1 } from 'components/text/title';
import useLocales from 'hooks/useLocales';
import SvgIconStyle from 'components/SvgIconStyle';

interface CandidateCardProps {
  title: string;
  children: ReactNode;
  onEdit?: (() => void) | null;
  groupsData?: any;
  iconSrc?: string;
  type?: string;
  withoutBox?: boolean;
  infoIcon?: ReactNode;
  style?: CSSProperties;
}

const ModalInfo = ({
  groups,
  open,
  title,
  onClose,
  infoIcon
}: {
  groups: any;
  open: boolean;
  title: string;
  infoIcon?: ReactNode;
  onClose: () => void;
}) => (
  <Dialog maxWidth="lg" open={open} onClose={onClose}>
    <Box
      sx={{
        width: 'auto',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DialogTitle>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <Box m="24px">{infoIcon}</Box>
    </Box>
    <DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {groups.map(({ groupTitle, list }: SortedGroupsType) => (
          <Box sx={{ width: '19%' }} key={groupTitle}>
            <Typography variant="h6">{groupTitle}</Typography>

            {list.map((el: SortedGroupsListType, i: number) => (
              <Tooltip
                arrow
                placement="left"
                TransitionComponent={Zoom}
                title={el.description}
                key={i}
              >
                <Box>
                  <ProgressBar
                    label={el.title}
                    max={MAX_COMPETENCY_SLIDER_RANGE}
                    value={el.point}
                  />
                </Box>
              </Tooltip>
            ))}
          </Box>
        ))}
      </Box>
    </DialogContent>
  </Dialog>
);

const CandidateCardContainer = ({
  title,
  onEdit,
  children,
  groupsData,
  iconSrc,
  type,
  withoutBox,
  infoIcon,
  style
}: CandidateCardProps) => {
  const { t } = useLocales();
  const [open, setOpen] = useState(false);

  return (
    <Card
      sx={{ p: 1, my: 1, overflow: 'initial', position: 'initial'}}
      style={{ ...(withoutBox ? { boxShadow: 'none' } : {}), ...style }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {iconSrc && (
            <SvgIconStyle
              src={iconSrc}
              style={{
                ...(withoutBox
                  ? { margin: '10px 10px 0 0' }
                  : { marginRight: '10px' })
              }}
            />
          )}
          <Title1
            padding={0}
            style={{ ...(withoutBox ? { marginBottom: '10px' } : {}) }}
          >
            {title}
          </Title1>
          {groupsData && (
            <>
              <Button
                onClick={() => setOpen(true)}
                variant="text"
                style={{
                  color: '#1C9DD7',
                  padding: '10px 30px',
                  marginLeft: 'auto'
                }}
              >
                {t('More info')}
                <KeyboardDoubleArrowRightIcon
                  style={{
                    fontSize: '18px',
                    margin: '0 0 5px 5px'
                  }}
                />
              </Button>
              <ModalInfo
                title={title}
                groups={groupsData}
                open={open}
                onClose={() => setOpen(false)}
                infoIcon={infoIcon}
              />
            </>
          )}
          {type === 'upload_files' ? (
            <UploadButton onClick={onEdit} style={{ marginLeft: 'auto' }} />
          ) : (
            onEdit && (
              <EditButton onClick={onEdit} style={{ marginLeft: 'auto' }} />
            )
          )}
        </Box>
        <Box>{children}</Box>
      </CardContent>
    </Card>
  );
};

export default CandidateCardContainer;
