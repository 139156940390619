import React, { useMemo, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/material';
import { DEFAULT_MESSAGE_TEXTS, getTranslatedList } from 'constants/constants';

import { PATH_DASHBOARD } from 'routes/paths';
import {
  cancelCandidateApplication,
  getCandidateRemind,
  updateApplicationData
} from 'requests/candidate';
import useLocales from 'hooks/useLocales';
import { getPlaceholders } from 'constants/text/placeholders';
import { getButtons } from 'constants/text/buttons';
import { ApplicantProps } from 'types/Applicant';
import { fDate } from 'utils/formatTime';
import { Block, TaskAlt } from '@material-ui/icons';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ScoreField from './ScoreField';
import { GridStyleContainer } from './CardCandidateLow';
import { DeleteButton } from './Buttons';
import { OverviewSelect } from './OverviewFilter';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import { useSnackbar } from 'notistack';
import useAuth from '@hooks/useAuth';
import MenueSelect from './MenueSelect';
import { DeleteDialog } from './Dialog';
import { AddTeamMemberButton } from './AddTeamMemberButton';

const CandidateLink = ({ id, name, avatar, style = {} }: any) => (
  <RouterLink
    to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(id)}
    style={{ margin: '10px 0', display: 'flex', textDecoration: 'none' }}
  >
    <Avatar alt={name} src={avatar || ''} sx={{ mr: 1 }} style={style} />
    <Typography variant="h4">{name}</Typography>
  </RouterLink>
);

const ActionBox = styled(Box)(() => ({
  minWidth: '180px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
}));

const ApplicantCard = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 992px)': {
    flexDirection: 'column',
    rowGap: '20px',
    div: {
      maxWidth: 'max-content !important'
    },
    '.MuiAvatar-root': {
      maxWidth: '60px !important'
    }
  },
  width: '100%'
}));

const CardWrapper = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  padding: '35px 20px',
  '@media (max-width: 992px)': {
    flexDirection: 'column',
    rowGap: '20px',
    div: {
      maxWidth: 'max-content !important'
    },
    '.MuiAvatar-root': {
      maxWidth: '60px !important'
    }
  },
  marginBottom: '8px',
  width: '100%'
}));

const GridProfile = styled(Grid)(() => ({
  alignItems: 'center',
  width: '300px',
  '@media (max-width: 687px)': {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

export const CandidateCard = ({
  item: {
    id,
    candidate: { id: candidateId, name, avatar, anonymous, user: userId },
    status,
    stage,
    vacancy
  },
  setIsRefresh,
  handleOpenApplicantModal,
  handleOpenChat,
  isRefresh,
  item
}: ApplicantProps) => {
  const {
    user,
    isAdmin,
    isRecruiterPlus,
    isRecruiter,
    isCandidate,
    isHiringManager
  } = useAuth();

  const [locStatus, setLocStatus] = useState(status);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSelect = Boolean(anchorEl);
  const [inputValue, setInputValue] = useState('');
  const [onpentextInput, setOnpentextInput] = useState(false);
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const { t } = useLocales();
  const { ANONYMOUS } = getPlaceholders();
  const { STAGE_TYPES } = getTranslatedList();
  const { enqueueSnackbar } = useSnackbar();

  const {
    ACCEPT,
    DECLINE,
    DELETE,
    APPLICANTS: { CANCEL_APPLICATION }
  } = getButtons();

  const reasons = [
    t('Not qualified for the team'),
    t('Lack of knowledge'),
    t('Hired somewhere else'),
    t('Salary too high'),
    t('Lack of personality skills'),
    t('Lack of hard skills'),
    t('Spam')
  ];
  const [candidateAvatar, candidateName, isOwner] = useMemo(() => {
    const isOwner = vacancy?.user === user?.id;
    const candidateAvatar = isOwner || !anonymous ? avatar : null;
    const candidateName = isOwner || !anonymous ? name : ANONYMOUS;
    return [candidateAvatar, candidateName, isOwner];
  }, [userId, user?.id, anonymous]);

  const canTakeAction =
    isAdmin || isRecruiterPlus || isHiringManager || (isRecruiter && isOwner);

  const canAddMemberToTeam =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  const hasTakenAssessment =
    item?.candidate?.competencies && item.candidate.competencies.length > 0;

  const handleApplicationChange = async (
    status?: string | number,
    stage?: string | number,
    decline_reason?: string
  ) => {
    if (!isCandidate) {
      try {
        await updateApplicationData(id, {
          ...(stage && { stage }),
          ...(status && { status }),
          ...(decline_reason && { decline_reason })
        });
      } catch (_) {
        setIsRefresh(!isRefresh);
      }
    }
    setIsRefresh(!isRefresh);
  };

  if (locStatus === 3) return null;

  const handleDeleteMyApplication = async () => {
    try {
      cancelCandidateApplication(id);
      setIsRefresh(!isRefresh);
      enqueueSnackbar(t('Application deleted successfully'), {
        variant: 'success'
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('Something went wrong, please try again later'), {
        variant: 'error'
      });
    }
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOnpentextInput(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleApplicationChange(3, '', inputValue.trim());
      setLocStatus(3);
      handleClose();
    }
  };

  const handleMenueItemClick = (value: any) => {
    handleApplicationChange(3, '', value.toString());
    setLocStatus(3);
    handleClose();
  };

  const handleRemind = async () => {
    const res = await getCandidateRemind(candidateId);
    try {
      if (res) {
        enqueueSnackbar(t('Reminded successfully'), { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  return (
    <CardWrapper>
      <ApplicantCard>
        <GridProfile container style={{ flexWrap: 'nowrap' }}>
          <CandidateLink
            id={isCandidate ? 'me' : candidateId}
            avatar={candidateAvatar}
            style={{ height: '60px', width: '60px' }}
          />
          <Grid>
            <Typography
              variant="caption"
              style={{
                marginBottom: '20px',
                fontWeight: 400,
                fontSize: '12px'
              }}
            >
              {fDate(item.created_at)}
            </Typography>
            {!isCandidate && (
              <Typography
                variant="h5"
                padding={0}
                style={{
                  fontWeight: 700,
                  fontSize: '20px'
                }}
              >
                {candidateName}{' '}
                {company.is_agency && item?.vacancy && item?.vacancy?.customer
                  ? `[${item.vacancy?.customer?.name}]`
                  : ''}
              </Typography>
            )}
            <Link
              to={PATH_DASHBOARD.jobPosts.getJobPostUrl(vacancy?.id)}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="h5" color="GrayText" padding={0}>
                {vacancy?.title || ''}
              </Typography>
            </Link>
          </Grid>
        </GridProfile>
        <Grid
          spacing={1}
          style={{
            width: '150px'
          }}
        >
          <Grid item>
            <GridStyleContainer>
              {!!(item?.score || item?.culture_score) && (
                <>
                  <ScoreField score={item?.score} type="talent" fontSize={20} />
                  <ScoreField
                    score={item?.culture_score}
                    type="culture"
                    fontSize={20}
                  />
                </>
              )}
            </GridStyleContainer>
          </Grid>
        </Grid>
        {!isCandidate && (
          <Grid
            item
            style={{
              width: '220px'
            }}
          >
            <GridStyleContainer>
              {locStatus !== 2 && locStatus !== 3 ? (
                <ActionBox
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '10px'
                  }}
                >
                  {canTakeAction && (
                    <>
                      <Button
                        variant="outlined"
                        color="success"
                        disabled={locStatus === 2}
                        onClick={() => {
                          handleOpenChat(item);
                          handleApplicationChange(2);
                          setLocStatus(2);
                        }}
                        style={{
                          borderRadius: '30px',
                          borderWidth: '2px',
                          width: '100%'
                        }}
                      >
                        <TaskAlt />
                        {ACCEPT}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disabled={locStatus === 3}
                        onClick={handleClick}
                        style={{
                          borderRadius: '30px',
                          borderWidth: '2px',
                          width: '100%'
                        }}
                      >
                        <Block />
                        {DECLINE}
                      </Button>
                      <MenueSelect
                        open={openSelect}
                        anchorEl={anchorEl}
                        options={reasons}
                        onClose={handleClose}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        handleKeyPress={handleKeyPress}
                        handleMenueItemClick={handleMenueItemClick}
                        onpentextInput={onpentextInput}
                        setOnpentextInput={setOnpentextInput}
                      />
                    </>
                  )}
                </ActionBox>
              ) : null}

              {(Number(status) > 0 || Number(locStatus) > 0) && (
                <Box width="100%" minWidth="180px">
                  <OverviewSelect
                    width="100% "
                    candidate={{ color: '#9B5FDA' }}
                    options={STAGE_TYPES}
                    activeOption={stage}
                    setOption={(value: string | number) =>
                      handleApplicationChange(undefined, value)
                    }
                    styleAutocomplete={{
                      background: 'rgba(155, 95, 218, 0.2)',
                      borderRadius: '30px'
                    }}
                  />
                  <DeleteButton
                    sx={{
                      width: '100%',
                      borderRadius: '30px',
                      borderWidth: '2px',
                      height: '38px'
                    }}
                    variant="outlined"
                    color="error"
                    disabled={locStatus === 3}
                    onClick={() => {
                      handleApplicationChange(3);
                      setLocStatus(3);
                    }}
                    buttontext={DELETE}
                  />
                </Box>
              )}
              {canAddMemberToTeam && (
                <AddTeamMemberButton
                  candidateId={candidateId?.toString() || ''}
                  style={{ marginTop: '8px' }}
                />
              )}
            </GridStyleContainer>
          </Grid>
        )}
        {isCandidate && (
          <Grid item mr={2}>
            <Typography
              sx={{
                borderRadius: 30,
                background: 'rgba(155, 95, 218, 0.4)',
                fontWeight: 700,
                fontSize: '14px',
                color: '#FFFFFF',
                padding: '10px 24px',
                textAlign: 'center',
                mb: '12px'
              }}
            >
              {STAGE_TYPES.find((s) => s.value === stage)?.label || ''}
            </Typography>
            <GridStyleContainer>
              <Box>
                <DeleteDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  title={t('Are you sure you want to cancel your application?')}
                  onDelete={handleDeleteMyApplication}
                />
                <Button
                  style={{ width: 'max-content' }}
                  variant="outlined"
                  onClick={() => setOpen(true)}
                >
                  {CANCEL_APPLICATION}
                </Button>
              </Box>
            </GridStyleContainer>
          </Grid>
        )}
        {handleOpenApplicantModal && (
          <KeyboardDoubleArrowRightIcon
            style={{
              fontSize: '30px',
              margin: '0 0 5px 5px',
              color: '#1C9DD7',
              cursor: 'pointer'
            }}
            onClick={() => handleOpenApplicantModal(item)}
          />
        )}
      </ApplicantCard>
      {canTakeAction && !hasTakenAssessment && (
        <ButtonPrimary
          style={{
            width: '100%',
            borderRadius: '30px',
            cursor: 'pointer',
            padding: '5px 15px'
          }}
          onClick={handleRemind}
        >
          {t('Remind the candidate to take personality assessment')}
        </ButtonPrimary>
      )}
    </CardWrapper>
  );
};
