import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Avatar,
  ListItemText,
  List,
  ListItemAvatar,
  Button
} from '@material-ui/core';
import { KeyboardDoubleArrowDownOutlined as KeyboardDoubleArrowDownOutlinedIcon } from '@mui/icons-material';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';
import { deleteTeamData, getTeamsData } from 'redux/slices/teams';
import { PATH_DASHBOARD } from 'routes/paths';
import MButton from '@components/new-designer/button/MButton';
import { DeleteButton } from '../Buttons';
import { Team, TeamCandidate } from './types';

export interface TeamPostProps {
  item: Team;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 600,
  fontSize: '13px',
  color: '#131417',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '25px'
}));

const CardStyles = styled(Card)((theme: any) => ({
  '&:hover': {
    ...(theme?.fromCandidate
      ? {
          boxShadow:
            '0 0 100px 0 rgb(145 158 171 / 24%), 0 0 100px 0 rgb(145 158 171 / 24%)'
        }
      : {})
  },
  height: '500px',
  maxHeight: '500px'
}));

const HrDivider = styled(Divider)(() => ({
  height: 0,
  border: '0.6px solid #C9CAD9'
}));

export const TeamCard = ({ item, setRefresh }: TeamPostProps) => {
  const { t } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAdmin, isRecruiterPlus, isRecruiter, isHiringManager } =
    useAuth();

  const canDeleteTeam =
    isAdmin || isRecruiterPlus || (isRecruiter && item.user_name === user.name);

  const canAddMemberToTeam =
    isAdmin || isRecruiterPlus || isRecruiter || isHiringManager;

  const CandidatesPaths = [
    {
      name: t('Applicants Overview'),
      path: '/candidates/#applicants'
    },
    {
      name: t('Candidates Overview'),
      path: '/candidates/#candidates-overview'
    },
    { name: t('Talent Pool'), path: '/candidates/#talent-pool' }
  ];

  const handleDeleteTeam = (teamId: number) => {
    dispatch(deleteTeamData(teamId));
    dispatch(getTeamsData({ limit: 6, offset: 0 }));
    // setRefresh((prevState) => !prevState);
  };

  const getInitials = (name: string) => {
    const words = name.toUpperCase().split(' ');
    let initials = words[0][0];
    if (words.length > 1) {
      initials += words[words.length - 1][0];
    }
    return initials;
  };

  const getCandidateName = (candidate: TeamCandidate) => candidate.name;

  const redirectToCandidates = (path: string) => {
    navigate(path);
  };

  const hasMembers = item?.candidates && item?.candidates.length > 0;

  if (!item) {
    return <></>;
  }

  return (
    <>
      <CardStyles>
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <RouterLink
                  to={
                    hasMembers
                      ? PATH_DASHBOARD.candidates.getTeamID(item.id)
                      : '/candidates/#teams'
                  }
                  style={{
                    margin: '10px 0',
                    display: 'flex',
                    textDecoration: 'none',
                    cursor: hasMembers ? 'pointer' : 'default'
                  }}
                >
                  <TitleTypography
                    variant="h4"
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    {item.name}
                  </TitleTypography>
                </RouterLink>
                {canDeleteTeam && (
                  <DeleteButton
                    dialogText={t('Are you sure you want to delete this Team?')}
                    onClick={() => handleDeleteTeam(item.id)}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid display="flex" justifyContent="space-between">
                <Box display="flex" width="100%" justifyContent="space-between">
                  {item.user_name && (
                    <Typography variant="caption" color="GrayText" mb={1}>
                      {t(`Created by ${item.user_name}`)}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <HrDivider />
              <Grid
                item
                xs={12}
                my="12px"
                sx={{ maxHeight: '265px', overflowY: 'auto' }}
              >
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper'
                  }}
                >
                  {hasMembers &&
                    item.candidates.map((candidate, index) => (
                      <Box
                        key={candidate.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Button
                          onClick={() =>
                            navigate(
                              PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                                candidate.id
                              )
                            )
                          }
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt="Remy Sharp"
                              sx={{
                                width: '40px',
                                height: '40px'
                              }}
                            >
                              {getInitials(getCandidateName(candidate))}
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={getCandidateName(candidate)}
                            style={{ maxWidth: 'fit-content' }}
                          />
                        </Button>
                      </Box>
                    ))}
                </List>
              </Grid>
              {item?.candidates?.length > 5 && (
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <KeyboardDoubleArrowDownOutlinedIcon />
                  <Typography variant="caption">
                    {t('Scroll for more')}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {hasMembers && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <RouterLink
                to={PATH_DASHBOARD.candidates.getTeamID(item.id)}
                style={{ width: '100%' }}
              >
                <MButton
                  title={t('See candidate competencies')}
                  MyClass="violetButton"
                  style={{ width: '100%' }}
                />
              </RouterLink>
            </Box>
          )}
          {!hasMembers && (
            <Box
              sx={{
                height: '60%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <TitleTypography
                  variant="h6"
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {t('This team has no members yet!')}
                </TitleTypography>
                {canAddMemberToTeam && (
                  <Typography variant="caption" mt={2}>
                    {t('You can add members from')}:
                  </Typography>
                )}
              </Box>
              {canAddMemberToTeam && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '8px'
                  }}
                >
                  {CandidatesPaths.map(
                    (CandidatePath: { name: string; path: string }) => (
                      <MButton
                        key={CandidatePath.name}
                        title={CandidatePath.name}
                        MyClass="violetButton"
                        click={() => redirectToCandidates(CandidatePath.path)}
                        style={{ width: '100%' }}
                      />
                    )
                  )}
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </CardStyles>
    </>
  );
};
