import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import CandidateModal from 'components/CandidateModal';
import { getCompetenciesScoresAndLabels } from 'helpers/listValues';
import { Grid, Typography } from '@mui/material';
import { Box, Slider } from '@material-ui/core';
import { Description2 } from 'components/text/title';
import * as ExcelJS from 'exceljs';
import {
  deleteSelectedCandidate,
  getSelectedCandidatesData
} from 'requests/profile';
import XLSXButton from 'components/XLSXButton';
import styled from '@emotion/styled';
import useURLFilters from '@hooks/useURLFilters';
import { RootState } from '../../redux/store';
import {
  getMatchProfiles,
  getSelectedCandidates
} from '../../redux/slices/generalRecruiter';
import Page from '../../components/containers/Page';
import CardCandidateLow from '../../components/CardCandidateLow';
import {
  OverviewSearch,
  OverviewSelect
} from '../../components/OverviewFilter';
import PageContainer from '../../components/containers/PageContainer';
import ListContainer from '../../components/containers/ListContainer';
import PaginationContainer from '../../components/containers/PaginationContainer';
import { ResetButton } from '../../components/Buttons';
import {
  resetCandidatesFilters,
  setCandidatesFilter
} from '../../redux/slices/filters';
import { getTranslatedList, LANGUAGES } from '../../constants/constants';
import useLocales from '../../hooks/useLocales';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import { PATH_DASHBOARD } from '../../routes/paths';
import { CandidateGrid, FilterGroupRight } from '../../styled';

const TypographyFilter = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#131417',
  marginBottom: '33px'
}));

const SelectedCandidates = ({ style = {} }) => {
  const { t } = useLocales();
  const {
    PERIOD_TYPES,
    STAGE_TYPES,
    SCORE_TYPES,
    DEGREE_TYPES,
    SALARY_CHOICES,
    YEAR_CHOICES,
    SALARY_CHOICES_INR
  } = getTranslatedList();
  const [isRefresh, setIsRefresh] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<any>({});
  const dispatch = useDispatch();
  const selectedCandidates = useSelector(
    (state: RootState) => state.generalRecruiter.selectedCandidates
  );
  const { results, count } = selectedCandidates;
  const matchProfiles = useSelector(
    (state: RootState) => state.generalRecruiter.matchProfiles
  );
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const filters = useSelector(
    (state: RootState) => state.filtersReducer.candidatesOverview
  );
  const {
    page,
    profileID,
    periodicity,
    limit,
    offset,
    query,
    stage,
    min_score,
    min_score_culture,
    default_lang,
    salaries,
    degrees,
    locations,
    skills,
    work_exps
  } = filters;

  const { results: MatchProfileResults } = matchProfiles;

  const handleClose = () => {
    setModalIsOpen(false);
    setCurrentCandidate({});
  };

  const handleOpen = (candidate: any) => {
    setModalIsOpen(true);
    setCurrentCandidate(candidate);
  };

  useEffect(() => {
    dispatch(getMatchProfiles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCandidatesFilter('offset', page * limit - limit));
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(
      getSelectedCandidates(
        profileID,
        periodicity,
        limit,
        offset,
        stage,
        query,
        null,
        min_score,
        min_score_culture,
        default_lang,
        salaries,
        degrees,
        locations,
        skills.map((skill: any) => skill.id || skill),
        work_exps
      )
    );
  }, [
    dispatch,
    profileID,
    periodicity,
    limit,
    offset,
    query,
    isRefresh,
    stage,
    min_score,
    min_score_culture,
    default_lang,
    salaries,
    degrees,
    locations,
    skills,
    work_exps
  ]);

  const onMoveCandidateToBlacklist = async (id: any) => {
    if (id) {
      await deleteSelectedCandidate(id);
      dispatch(
        getSelectedCandidates(
          profileID,
          periodicity,
          limit,
          offset,
          stage,
          query,
          null,
          min_score,
          min_score_culture,
          default_lang
        )
      );
    }
  };

  const onCreateSpreadsheet = async () => {
    const data: string[][] = [
      [
        'full name',
        'email address',
        'profile link',
        'match profile title',
        'preferred language'
      ]
    ];
    const res = await getSelectedCandidatesData({
      profileID,
      periodicity,
      limit: null,
      offset: null,
      stage,
      query,
      ordering: null,
      min_score,
      min_score_culture,
      default_lang
    });
    const response = res?.results;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    if (response && response?.length) {
      for (let i = 0; i < response.length; i++) {
        const elem = response[i];
        data.push([
          elem.candidate.name,
          elem.candidate.email,
          `${window.origin}${PATH_DASHBOARD.candidates.getCandidateProfileUrl(
            elem.candidate.id
          )}`,
          elem.profile.name,
          elem.candidate.default_lang === 2 ? 'Dutch' : 'English'
        ]);
      }
    }

    worksheet.addRows(data);
    worksheet.getColumn('A').width = 20;
    worksheet.getColumn('B').width = 30;
    worksheet.getColumn('C').width = 50;
    worksheet.getColumn('D').width = 30;
    worksheet.getColumn('E').width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Selected-Candidate-Recrout.xlsx';
      a.click();
    });
  };

  const filterNames = [
    'limit',
    'offset',
    'profileID',
    'periodicity',
    'default_lang',
    'salaries',
    'degrees',
    'locations',
    'skills',
    'work_exps',
    'min_score',
    'min_score_culture',
    'stage',
    'query'
  ];

  const currentFilters = useSelector(
    (state: RootState) => state.filtersReducer.candidatesOverview
  );

  useURLFilters({
    fragmentIdentifier: '#candidates-overview/',
    filterNames,
    setterFunction: setCandidatesFilter,
    currentFilters
  });

  return (
    <Page title={t('Candidates Overview')}>
      <CandidateModal
        open={modalIsOpen}
        handleClose={handleClose}
        candidate={currentCandidate}
        profile={getCompetenciesScoresAndLabels(
          currentCandidate?.profile?.competencies
        )}
        cultureProfile={getCompetenciesScoresAndLabels(
          currentCandidate?.profile?.culture_profile?.competencies
        )}
      />
      <PageContainer style={style}>
        <DashboardNavbar title={t('Candidates Overview')} />

        <CandidateGrid container xs={12}>
          <Grid style={{ width: '100%' }}>
            <ListContainer
              list={results}
              card={CardCandidateLow}
              candidateCards={true}
              getCardProps={(item: any) => ({
                selectedId: item.id,
                candidate: item.candidate,
                profile: item.profile,
                isRefresh,
                scores: {
                  score: item.score,
                  culture_score: item.culture_score
                },
                stage: item.stage,
                setIsRefresh,
                handleOpen,
                onMoveCandidateToBlacklist
              })}
            />
          </Grid>
          <FilterGroupRight>
            <TypographyFilter>{t('Filter by')}</TypographyFilter>
            <OverviewSearch
              placeholder={t('Search by Name')}
              setValue={useCallback(
                (value: any) => dispatch(setCandidatesFilter('query', value)),
                [dispatch]
              )}
              value={query}
            />
            <Description2 variant="h5">
              {t('Filter by talent match')}
            </Description2>
            <Slider
              style={{
                margin: '10px 10px 30px 10px',
                width: 'auto'
              }}
              value={min_score}
              min={0}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%'
                },
                {
                  value: 100,
                  label: '100%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={(value: any) =>
                dispatch(setCandidatesFilter('min_score', value.target.value))
              }
            />

            <Description2 variant="h5">
              {t('Filter by culture fit')}
            </Description2>
            <Slider
              style={{
                margin: '10px 10px 30px 10px',
                width: 'auto'
              }}
              value={min_score_culture}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%'
                },
                {
                  value: 100,
                  label: '100%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={(value: any) =>
                dispatch(
                  setCandidatesFilter('min_score_culture', value.target.value)
                )
              }
            />

            {MatchProfileResults && (
              <OverviewSelect
                title={t('Match Profile ')}
                width="100%"
                placeholder={t('Match Profile')}
                activeOption={profileID}
                options={MatchProfileResults}
                setOption={(value: any) =>
                  dispatch(setCandidatesFilter('profileID', value))
                }
              />
            )}
            <OverviewSelect
              title={t('Time period')}
              width="100%"
              activeOption={periodicity}
              options={PERIOD_TYPES}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('periodicity', value))
              }
            />
            <OverviewSelect
              title={t('Candidate stage ')}
              width="100%"
              activeOption={stage}
              options={STAGE_TYPES}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('stage', value))
              }
            />
            <OverviewSelect
              title={t('Preferred Language')}
              width="100%"
              activeOption={default_lang}
              options={LANGUAGES}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('default_lang', value))
              }
            />
            <OverviewSelect
              title={t('Location')}
              width="100%"
              type="location"
              placeholder={t('Locations')}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('locations', value))
              }
            />
            <OverviewSelect
              title={t('Degree')}
              width="100%"
              placeholder={t('Degree')}
              activeOption={degrees}
              options={DEGREE_TYPES}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('degrees', value))
              }
            />
            <OverviewSelect
              title={t('Salary')}
              width="100%"
              placeholder={t(`Salary (${company.currency})`)}
              activeOption={salaries}
              options={
                company.currency == 'INR' ? SALARY_CHOICES_INR : SALARY_CHOICES
              }
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('salaries', value))
              }
            />
            <OverviewSelect
              title={t('Skill')}
              width="100%"
              type="select-skills"
              placeholder={t('Skill')}
              activeOption={skills || []}
              setOption={(value: any) => {
                const selectedOption = value.reduce((acc: any[], v: any) => {
                  if (!acc.includes(v)) {
                    acc.push(v);
                  }
                  return acc;
                }, []);
                dispatch(setCandidatesFilter('skills', selectedOption));
              }}
              isPlaceholder={true}
              multiple={true}
              freeSolo={true}
              filterSx={{ maxWidth: '315px' }}
            />
            <OverviewSelect
              title={t('Experience')}
              width="100%"
              placeholder={t('Career Level')}
              activeOption={work_exps}
              options={YEAR_CHOICES}
              setOption={(value: any) =>
                dispatch(setCandidatesFilter('work_exps', value))
              }
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pb: 2,
                cursor: 'pointer',
                margin: '0 auto'
              }}
              onClick={() => dispatch(resetCandidatesFilters())}
            >
              <Typography
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 700,
                  fontSize: '18px',
                  color: '#562C82'
                }}
              >
                {t('Reset All')}
              </Typography>
              <ResetButton />
            </Box>
            <XLSXButton
              onClick={onCreateSpreadsheet}
              style={{
                position: 'unset',
                flexDirection: 'row-reverse',
                justifyContent: 'center',
                columnGap: '3px'
              }}
              textStyle={{
                fontWeight: 700,
                fontSize: '18px',
                color: 'rgb(86, 44, 130)'
              }}
              text="Export Candidate Data"
            />
          </FilterGroupRight>
        </CandidateGrid>
        <PaginationContainer
          page={page}
          count={count}
          perPage={limit}
          setPage={(value) => dispatch(setCandidatesFilter('page', value))}
        />
      </PageContainer>
    </Page>
  );
};

export default SelectedCandidates;
