import { createSlice } from '@reduxjs/toolkit';
import { initialCompany, initialPaginated } from 'constants/states';
import {
  createSelectedCandidateStage,
  getProfiles,
  getSelectedCandidatesData
} from 'requests/profile';
import {
  getCompanyData,
  updateCompanyData,
  updateCustomTextData
} from 'requests/account';
import { AppDispatch } from 'redux/store';
import { setDashboardLoading } from './loading';
import { resetCandidate } from './candidate';
import { getCompanyModuleInsights } from './dashboard';
import { enqueueSnackbar } from './notifications';

const initialState = {
  dashboardLoading: false,
  company: initialCompany,
  cultureProfilesLoading: false,
  cultureProfilesCount: null,
  cultureProfiles: initialPaginated,
  matchProfiles: initialPaginated,
  selectedCandidatesLoading: false,
  selectedCandidates: initialPaginated
};

const slice = createSlice({
  name: 'generalRecruiterSlice',
  initialState,
  reducers: {
    getCompanyInfoSuccess(state, action) {
      state.company = action.payload.company;
    },
    setDashboardLoading(state, action) {
      state.dashboardLoading = action.payload;
    },
    setCultureProfilesLoading(state, action) {
      state.cultureProfilesLoading = action.payload;
    },
    setCultureProfilesCount(state, action) {
      state.cultureProfilesCount = action.payload;
    },
    getCultureProfileSuccess(state, action) {
      state.cultureProfiles = action.payload.cultureProfiles;
    },
    getMatchProfileSuccess(state, action) {
      state.matchProfiles = action.payload.matchProfiles;
    },
    getSelectedCandidatesSuccess(state, action) {
      state.selectedCandidates = action.payload.selectedCandidates;
    },
    setSelectedCandidatesLoading(state, action) {
      state.selectedCandidatesLoading = action.payload;
    },
    selectedCandidateSuccess(state, action) {
      state.selectedCandidates.results = state.selectedCandidates.results
        ? [
            ...(state.selectedCandidates.results || []),
            action.payload.selectedCandidate
          ]
        : [action.payload.selectedCandidate];
    }
  }
});
export default slice.reducer;

export const getCultureProfilesCount = () => async (dispatch: AppDispatch) => {
  getProfiles(true, { limit: 1, offset: 0 })
    .then((res) => dispatch(slice.actions.setCultureProfilesCount(res?.count)))
    .catch(() => {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    });
};

export const getCultureProfiles =
  (limit?: number, offset?: number) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setCultureProfilesLoading(true));
    getProfiles(true, {
      limit: limit || 5,
      offset: offset || 0
    })
      .then((res) =>
        dispatch(
          slice.actions.getCultureProfileSuccess({
            cultureProfiles: res
          })
        )
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      })
      .finally(() => dispatch(slice.actions.setCultureProfilesLoading(false)));
  };

export const getMatchProfiles =
  (limit?: number, offset?: number) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setCultureProfilesLoading(true));
    getProfiles(false, {
      ...(limit ? { limit } : {}),
      offset: offset || 0
    })
      .then((res) =>
        dispatch(
          slice.actions.getMatchProfileSuccess({
            matchProfiles: res
          })
        )
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      })
      .finally(() => dispatch(slice.actions.setCultureProfilesLoading(false)));
  };

export const getCompanyInfo = () => async (dispatch: AppDispatch) =>
  getCompanyData()
    .then((res) =>
      dispatch(
        slice.actions?.getCompanyInfoSuccess({
          company: res[0]
        })
      )
    )
    .catch(() => {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    });

export const updateCompanyInfo =
  (id: string | number, formData: any) => async (dispatch: AppDispatch) =>
    updateCompanyData(id, formData)
      .then((res) =>
        dispatch(
          slice.actions?.getCompanyInfoSuccess({
            company: res
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });

export const selectCandidateFromFindCandidates =
  (candidateId: number | string, profileId: number | string) =>
  async (dispatch: AppDispatch) =>
    createSelectedCandidateStage({
      candidate: candidateId,
      profile: profileId
    })
      .then((res) =>
        dispatch(
          slice.actions.selectedCandidateSuccess({
            selectedCandidate: res
          })
        )
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      });

export const getSelectedCandidates =
  (
    profileID?: number | string | null,
    periodicity?: number | string | null,
    limit?: number | string | null,
    offset?: number | string | null,
    stage?: number | string | null,
    query?: number | string | null,
    ordering?: number | string | null,
    min_score?: number | string | null,
    min_score_culture?: number | string | null,
    default_lang?: number | null,
    salaries?: string | null,
    degrees?: string | null,
    locations?: string | null,
    skills?: any,
    work_exps?: string | null
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setSelectedCandidatesLoading(true));

    let skillsParam = [];

    if (skills && skills?.length > 1) {
      skillsParam = {
        skill: skills.map((skill: string | number) => skill)
      } as any;
    } else if (skills && skills.length > 0) {
      skillsParam = { skill: skills } as any;
    }

    getSelectedCandidatesData({
      profileID,
      periodicity,
      limit,
      offset,
      stage,
      query,
      ordering,
      min_score,
      min_score_culture,
      default_lang,
      salaries,
      degrees,
      locations,
      ...skillsParam,
      work_exps
    })
      .then((res) =>
        dispatch(
          slice.actions.getSelectedCandidatesSuccess({
            selectedCandidates: res || []
          })
        )
      )
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      })
      .finally(() =>
        dispatch(slice.actions.setSelectedCandidatesLoading(false))
      );
  };

export const getInitialDashboardData =
  (role: string) => async (dispatch: AppDispatch) => {
    dispatch(setDashboardLoading(true));
    try {
      await dispatch(getCompanyModuleInsights());
      if (role === 'recruiter' || role === 'admin') {
        dispatch(resetCandidate());
        await dispatch(getCultureProfilesCount());
      }
    } catch {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(setDashboardLoading(false));
    }
  };

export const updateCustomTexts =
  (id: string | number, data: any) => async (dispatch: AppDispatch) =>
    updateCustomTextData(id, data)
      .then((res) =>
        dispatch(
          slice.actions?.getCompanyInfoSuccess({
            company: res
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
