import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import {
  MAX_COMPETENCY_SLIDER_RANGE,
  MIN_COMPETENCY_SLIDER_RANGE
} from 'constants/constants';
import { Box, Grid, styled, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import MSwitch from '@components/@material-extend/MSwitch';
import useLocales from '@hooks/useLocales';
import { TeamCandidateTable } from './TeamCandidateTable';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface Data {
  comparePoints?: number[][]; // comparison data points
  labels: string[];
  points?: number[];
  candidateNames: string[];
  descriptions: string[];
}
interface Dataset {
  group: string;
  data: Data; // main data points
}

interface TeamChartProps {
  showTable?: boolean;
  setShowTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setChartRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null> | undefined>
  >;
  setTableRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null> | undefined>
  >;
  datasetsByGroup: Dataset[];
  labels: string[];
  showLegend?: boolean;
  withTable?: boolean;
}

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  width: '100%',
  height: '100%'
}));

const colors = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB'
];

const getColorForCandidate = (index: number) => colors[index % colors.length];

export const TeamChart: React.FC<TeamChartProps> = ({
  showTable,
  setShowTable,
  setChartRef,
  setTableRef,
  datasetsByGroup,
  labels,
  showLegend = false,
  withTable = false
}) => {
  const chartRef = useRef(null);
  const { t } = useLocales();
  const canvasRef = useRef(null);
  const [, setCreated] = useState<any>({});
// console.log("datasetsByGroup:",datasetsByGroup);

  useEffect(() => {
    if (setChartRef && chartRef.current) {
      for (let i = 0; i < 1; i++) {
        setChartRef(chartRef);
      }
    }
  }, [chartRef]);

  const datasets = datasetsByGroup
    .map((groupData) => {
      const {
        group,
        data: { comparePoints, labels, candidateNames, descriptions }
      } = groupData;

      // Ensure `comparePoints` is properly defined and has the expected number of items
      if (!comparePoints || comparePoints.length === 0) {
        console.warn(`Missing comparePoints data for group: ${group}`);
        return []; // Skip group if there's no comparePoints data
      }

      // Ensure the number of datasets corresponds to the number of candidates (length of comparePoints)
      const numberOfCandidates = comparePoints[0].length; // Get the number of candidates

      const candidateDatasets = [];

      // Create one dataset for each candidate
      for (
        let candidateIndex = 0;
        candidateIndex < numberOfCandidates;
        candidateIndex++
      ) {
        const candidatePoints = comparePoints.map(
          (pointsForLabel) => pointsForLabel[candidateIndex]
        );

        const candidateDataset = {
          label: `${group} - ${
            candidateNames[candidateIndex] || `Candidate ${candidateIndex + 1}`
          }`,
          data: candidatePoints, // Points for this candidate across all labels
          backgroundColor: `${getColorForCandidate(candidateIndex)}40`, // Transparent background
          borderColor: getColorForCandidate(candidateIndex),
          borderWidth: 1,
          pointBackgroundColor: getColorForCandidate(candidateIndex)
        };

        candidateDatasets.push(candidateDataset); // Add the dataset for this candidate
      }

      return candidateDatasets;
    })
    .flat();


console.log("datasetsByGroup:",datasetsByGroup);

console.log("datasets:",datasets);


  const drawTextAtIndex = (
    scale: any,
    index: number,
    label: string,
    offset = 36
  ) => {
    const radius = scale.drawingArea + offset; // Extend radius to draw text outside the chart
    const angle = scale.getIndexAngle(index) - Math.PI / 2; // Get angle for current index
    const x = scale.xCenter + Math.cos(angle) * radius; // X-coordinate for the text
    const y = scale.yCenter + Math.sin(angle) * radius; // Y-coordinate for the text
    const { ctx } = scale; // Chart's drawing context

    // Save context state before transformations
    ctx.save();

    // Prepare the text properties
    ctx.textAlign = 'center';
    ctx.font = "600 10px 'Rubik'";
    ctx.fillStyle = 'black';

    // Calculate lines for multi-line text support
    const textLines = splitTextToLines(ctx, label, 80); // Adjust maxWidth as needed

    // Draw each line of text with proper alignment
    textLines.forEach((line, i) => {
      const lineY = y + i * 12; // Adjust line height dynamically
      ctx.fillText(line, x, lineY);
    });

    // Restore context state after transformations
    ctx.restore();
  };

  /**
   * Helper function to split text into lines based on max width.
   * @param ctx - CanvasRenderingContext2D for measuring text width.
   * @param text - The text to split.
   * @param maxWidth - The maximum width of a single line.
   * @returns Array of text lines.
   */
  const splitTextToLines = (
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number
  ): string[] => {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const { width } = ctx.measureText(`${currentLine} ${word}`);
      if (width < maxWidth) {
        currentLine += ` ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    lines.push(currentLine);
    return lines;
  };

  const handleShowTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setShowTable) {
      setShowTable(event.target.checked);
    }
  };

  const titleCallback = (data: any) => {
    console.log(data);

    let text = '';
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const { datasetIndex } = element; // Get the dataset index
      const { dataIndex } = element; // Get the data index

      // Check if datasetIndex is valid
      if (datasetsByGroup[datasetIndex]) {
        const description = datasetsByGroup[datasetIndex].data.descriptions[dataIndex]; // Access the corresponding description

        // Only add the description if it exists
        if (description) {
          text += description; // Use the description instead of the label
          if (i < data.length - 1) {
            text += '\n';
          }
        }
      } else {
        console.warn(`Invalid datasetIndex: ${datasetIndex}`);
      }
    }

    return text;
  };

  return (
    <Grid
      item
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Wrapper ref={chartRef} >
        <Radar
          ref={canvasRef}
          data={{
            labels,
            datasets
          }}
          options={{
            animation: {
              duration: 0
            },
            scales: {
              r: {
                suggestedMin: MIN_COMPETENCY_SLIDER_RANGE,
                suggestedMax: MAX_COMPETENCY_SLIDER_RANGE,
                pointLabels: {
                  font: {
                    size: 14
                  },
                  color: 'transparent'
                },
                ticks: {
                  display: false,
                  maxTicksLimit: 6
                },
                grid: {
                  color: '#828282'
                },
                angleLines: {
                  color: '#828282'
                }
              }
            },
            plugins: {
              legend: {
                display: showLegend
              },
              tooltip: {
                callbacks: {
                  title: titleCallback
                },
                titleFont: {
                  size: 16
                },
                bodyFont: {
                  size: 16
                }
              }
            }
          }}
          plugins={[
            {
              id: 'custom_labels',
              afterDraw: (chart: any) => {
                const scale = chart.scales.r;
                chart.data.labels.forEach((label: string, index: number) => {
                  drawTextAtIndex(scale, index, label);
                });
              }
            }
          ]}
        />
      </Wrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
        <MSwitch
          checked={showTable}
          onChange={handleShowTable}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography>{t('View scores')}</Typography>
      </Box>
      <Box
        sx={{
          display: withTable && showTable ? 'flex' : 'none',
          width: '100%'
        }}
      >
        <TeamCandidateTable
          setTableRef={setTableRef}
          labels={labels}
          datasets={datasets}
        />
      </Box>
    </Grid>
  );
};
