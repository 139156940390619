import useLocales from '@hooks/useLocales';
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTeamMember,
  getTeamDataByID,
  getTeamsData
} from 'redux/slices/teams';

import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Paper,
  styled
} from '@material-ui/core';

import { RootState } from 'redux/store';
import { useDebounce } from '@hooks/useDebounce';
import { useSnackbar } from 'notistack';
import { getAllUserAndAdmin } from 'redux/slices/candidate';
import useAuth from '@hooks/useAuth';
import { CustomModal } from './CustomModal';
import MButton from './new-designer/button/MButton';
import { Team } from './team/types';

export interface TeamShort {
  candidates: number[];
  id: number;
  name: string;
  user_name: string;
}

const CustomPaper = (props: any) => (
  <Paper
    style={{
      background: 'white',
      fontSize: '14px',
      boxSizing: 'border-box',
      border: '1px solid #C9CAD9',
      borderBottom: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }}
    {...props}
  />
);

const StyledAutoComplete = styled(Autocomplete<TeamShort>)({
  width: '100%',
  marginTop: '24px',
  marginBottom: '24px'
});

export const AddTeamMemberButton = ({
  withTeamSelector = false,
  team,
  setRefresh,
  style,
  candidateId
}: {
  withTeamSelector?: boolean;
  team?: Team;

  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  style?: CSSProperties;
  candidateId?: string;
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState('');

  const [arrayCandidates, setArrayCandidates] = useState([]);
  const [selectedCandidateId, setSelectedCandidateId] = useState('');
  const [options, setOptions] = useState<TeamShort[]>();

  const debouncedValue = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();

  const teams = useSelector((state: RootState) => state.teams);

  const shortTeamsResults = teams.teamsShort?.results;

  const { allCandidates } = useSelector((state: RootState) => state.candidate);

  const toggleDialog = () => {
    setDialogOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isDialogOpen && !withTeamSelector) {
      dispatch(getTeamsData({ short: true, q: debouncedValue }));
    }
  }, [dispatch, debouncedValue]);

  useEffect(() => {
    if (isDialogOpen && withTeamSelector) {
      dispatch(getAllUserAndAdmin(userId, '', debouncedValue, 0));
    }
  }, [dispatch, userId, debouncedValue]);

  useEffect(() => {
    if (allCandidates.results) {
      const teamCandidateIds =
        team?.candidates.map((candidate) => candidate.id) || [];

      setArrayCandidates(
        allCandidates.results.filter(
          (candidate: any) => !teamCandidateIds.includes(candidate.id)
        )
      );
    }
  }, [allCandidates.results, team?.candidates]);

  const handleAddTeamMember = () => {
    if ((team?.id || selectedTeamId) && (candidateId || selectedCandidateId)) {
      const parsedTeamName =
        team?.name ||
        shortTeamsResults.find(
          (team: TeamShort) => team.id === Number(selectedTeamId)
        ).name;

      try {
        dispatch(
          addTeamMember(
            team?.id?.toString() || selectedTeamId,
            candidateId || selectedCandidateId
          )
        );
        if (setRefresh && team?.id) {
          dispatch(getTeamDataByID(team?.id));
          setRefresh((prev) => !prev);
        }
        toggleDialog();
        enqueueSnackbar(
          t(`Candidate added to team ${parsedTeamName} successfully`),
          {
            variant: 'success'
          }
        );
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('Failed to add candidate to the team'), {
          variant: 'error'
        });
      }
    }
  };

  const handleSearch = (event: any, newInputValue: string) => {
    setSearchTerm(newInputValue);
  };

  const handleTeamSelect = (event: any, newValue: any | null) => {
    if (newValue.id) {
      setSelectedTeamId(newValue.id);
    }
  };

  const handleCandidateSelect = (event: any, newValue: any | null) => {
    if (newValue) {
      setSelectedCandidateId(newValue.candidate_id);
    }
  };

  useEffect(() => {
    if (candidateId) {
      setOptions(
        shortTeamsResults?.filter(
          (res: TeamShort) =>
            !res.candidates || !res.candidates.includes(Number(candidateId))
        ) || []
      );
    }
  }, [candidateId, shortTeamsResults]);

  return (
    <>
      <MButton
        title={t('Add to the team')}
        MyClass="violetButton"
        click={toggleDialog}
        style={{ minWidth: 'fit-content', width: '100%', ...style }}
      />

      <CustomModal
        open={isDialogOpen}
        onClose={() => {
          setOptions([]);
          toggleDialog();
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" fontWeight={600} mb="8px">
            {withTeamSelector
              ? t('Select the candidate who you want to add this team')
              : t('Select the team to which you want to add this candidate')}
          </Typography>

          {!withTeamSelector && (
            <StyledAutoComplete
              options={options || []}
              getOptionLabel={(option: TeamShort) => option?.name || ''}
              id="team-select"
              onInputChange={handleSearch}
              onChange={handleTeamSelect}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('Search Teams')} />
              )}
              renderOption={(props, option: TeamShort) => (
                <li {...props}>{option?.name || ''}</li>
              )}
            />
          )}

          {withTeamSelector && (
            <StyledAutoComplete
              options={arrayCandidates || []}
              getOptionLabel={(option: any) =>
                `${option?.first_name} ${option?.last_name}` || ''
              }
              id="candidate-select"
              onInputChange={handleSearch}
              onChange={handleCandidateSelect}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('Search Candidates')} />
              )}
              renderOption={(props, option: any) => (
                <li {...props}>
                  {`${option?.first_name} ${option?.last_name}` || ''}
                </li>
              )}
            />
          )}

          <MButton
            title={t('Add')}
            MyClass="violetButton"
            click={handleAddTeamMember}
            style={{
              width: '100%'
            }}
          />
        </Box>
      </CustomModal>
    </>
  );
};
