import { styled } from '@mui/material';
import {
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { MIconButton } from '../components/@material-extend';
import { FilterGroup, OverviewSelect } from '../components/OverviewFilter';
import ReactQuill from 'react-quill';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const styleCompanyCard = {
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  padding: '40px'
};

export const cardStyle = {
  background:
    'linear-gradient(94deg, rgba(255, 255, 255, 0.80) -5.21%, rgba(255, 255, 255, 0.30) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  padding: '40px',
  marginTop: '50px'
};

export const styleCard = {
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  marginRight: '30px',
  marginTop: '0px',
  padding: '36px 30px 30px'
};

export const styleCmsCard = {
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  padding: '36px 30px 30px 30px',
  marginRight: '30px',
  height: 'max-content'
};

export const styleActive = {
  background: 'rgba(155, 255, 155, 0.2)',
  borderRadius: '30px',
  color: '#8AE98A',
  width: '86px',
  height: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Rubik',
  fontWeight: 700
};

export const styleAddNew = {
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  width: '206px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '191px'
};

export const useCmsStyles = makeStyles(() => ({
  root: {
    width: '100%',
    border: '1px solid #C9CAD9',
    overflow: 'hidden',
    borderRadius: 30,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Rubik',
    color: '#131417',
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: '#C9CAD9'
    }
  },
  date: {
    fontFamily: 'Rubik'
  }
}));

export const useCmsCareer = makeStyles(() => ({
  root: {
    width: '100%',
    border: '1px solid #C9CAD9',
    overflow: 'hidden',
    borderRadius: 30,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Rubik',
    color: '#131417',
    padding: '17px 25px 22px 25px',
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: '#C9CAD9'
    }
  }
}));

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: 30,
    backgroundColor: '#F5F7FF',
    border: '1px dashed #9B5FDA',
    maxWidth: '510px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '20px',
    color: '#562C82',
    maxWidth: '250px',
    margin: 0
  },
  icon: {
    filter:
      'invert(66%) sepia(115%) saturate(4443%) hue-rotate(232deg) brightness(97%) contrast(102%)',
    width: '83px',
    height: '60px'
  },
  customTooltip: {
    position: 'relative',
    left: '27px !important',
    top: '13px !important',
    maxWidth: '368px !important',
    width: '100% !important',
    padding: '58px 56px !important',
    borderRadius: '42px 42px 42px 0 !important',
    backgroundColor: '#ffffff !important'
  }
}));

export const useStylesDialog = makeStyles(() => ({
  container: {
    maxWidth: '510px',
    margin: '0 auto'
  }
}));

export const useModuleStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 'none',
    backgroundColor: theme.palette.common.white,
    color: 'orange'
  },
  switch_track: {
    backgroundColor: 'transparent',
    '&.MuiSwitch-track': {
      backgroundColor: 'transparent'
    }
  },
  switch_base: {
    color: '#FF6868',
    '&.Mui-checked': {
      color: '#8AE98A'
    }
  },
  switch_primary: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'transparent'
    }
  }
}));

export const GridPickerStyle = styled(Grid)(() => ({
  button: {
    width: '97px',
    height: '40px',
    borderRadius: '30px',
    margin: '0 10px 0 0'
  },
  '.MuiOutlinedInput-root': {
    borderRadius: '50px',
    height: '40px'
  },
  '.ColorPicker-MuiInput-underline': {
    border: '1px solid #C9CAD9',
    borderRadius: '20px',
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: 'none'
    },
    '&::after': {
      content: 'none'
    },
    input: {
      padding: '7px 0'
    }
  },
  '.MuiButton-label': {
    width: 'auto',
    div: {
      width: '97px',
      height: '40px',
      borderRadius: '30px'
    }
  },
  '.MuiFormControl-root': {
    height: '40px'
  }
}));

export const ReactQuillBody = styled(ReactQuill)(() => ({
  '.ql-snow': {
    background: '#FFFFFF',
    border: '1px solid #C9CAD9'
  },
  '.ql-snow:first-of-type': {
    borderRadius: '30px 30px 0 0',
    borderBottom: 0
  },
  '.ql-snow:last-of-type': {
    borderRadius: '0 0 30px 30px',
    borderTop: 0
  }
}));

export const StyledIconButton = styled(IconButton)`
  left: ${(props: any) => (props.isLeft ? '0' : 'none')};
  right: ${(props: any) => (props.isLeft ? 'none' : '0')};
  top: 24px;
  height: 32px;
  width: 32px;
  position: absolute;
  z-index: 1;
  opacity: 1;
  cursor: pointer;
  color: #562c82;

  :hover {
    height: 33px;
    width: 33px;
  }
`;

export const CardHeader = styled('div')(({ theme }: any) => ({
  paddingBottom: '0',
  color: '#252B42',
  textAlign: 'start'
}));

export const CardPrice = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start !important',
  flexDirection: 'column',
  marginBottom: '21px'
}));

export const cardInvoices = {
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  padding: '27px 30px 27px 40px',
  marginBottom: '11px',
  height: '77px'
};

export const cardSubscription = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  background: 'transparent',
  boxShadow: 'none',
  rowGap: '25px',
  alignItems: 'center'
};

export const CardFooter = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export const InactivePrice = styled('h2')(() => ({
  color: 'gray',
  marginRight: '10px',
  textDecoration: 'line-through'
}));

export const BlockWrapper = styled('div')(() => ({
  marginTop: '24px',
  marginBottom: '34px',
  display: 'flex',
  justifyContent: 'center'
}));

export const Heading = styled(Box)(() => ({
  width: '100%',
  height: 550,
  position: 'relative',
  borderRadius: 15
}));

export const TextFieldUrl = styled(TextField)(() => ({
  maxWidth: '400px',
  width: '100%',
  '.MuiOutlinedInput-root': {
    borderRadius: '30px'
  },
  input: {
    padding: '10px 30px'
  }
}));

export const OverviewSelectStyle = styled(OverviewSelect)(() => ({
  '@media (max-width: 1024px)': {
    width: '100%'
  }
}));

export const AccordionTitle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

export const ModuleCmsItem = styled('div')(() => ({
  borderBottom: '1px solid #D5D3D8',
  display: 'flex',
  alignItems: 'center',
  padding: '10px'
}));

export const SwitchBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const SwitchModuleBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '20px'
}));

export const AccordionSummaryStyles = styled(AccordionSummary)<{
  itemId: boolean;
}>(({ theme, itemId }) => ({
  minHeight: itemId ? 'inherit !important' : undefined,
  '.MuiAccordionSummary-content': {
    margin: itemId ? '0 !important' : undefined
  }
}));

export const CardWrapper = styled(Card)(() => ({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const MIconButtonStyle = styled(MIconButton)(() => ({
  '.MuiIconButton-label': {
    height: '100%'
  }
}));

export const GridLeftCard = styled(Grid)(() => ({
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  margin: 0,
  width: 'max-content',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  rowGap: '25px',
  maxWidth: '400px',
  padding: '40px'
}));

export const GridCard = styled(Grid)(() => ({
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  justifyContent: 'space-between',
  margin: 0,
  flexWrap: 'nowrap',
  width: '100%',
  // rowGap: '25px',
  padding: '25px 30px 25px 29px',
  maxHeight: '150px',
  alignItems: 'center'
}));

export const ChipStyle = styled(Chip)(() => ({
  background: '#9B5FDA',
  borderRadius: '30px',
  fontWeight: 600,
  fontSize: '14px',
  color: '#FFFFFF',
  padding: '10px 20px',
  height: 'auto',
  margin: '0 !important',
  span: {
    margin: '0 !important'
  }
}));

export const ChipStyleNew = styled(Chip)(() => ({
  background: 'rgba(86, 44, 130, 0.10)',
  borderRadius: '30px',
  fontWeight: 400,
  fontSize: '14px',
  color: '#562C82',
  padding: '8px 12px',
  height: 'auto',
  margin: '0 !important',
  span: {
    margin: '0 !important'
  }
}));

export const TypographyTarget = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '17px',
  color: '#131417'
}));

export const ModuleItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '29px 50px 29px 50px',
  borderRadius: '20px',
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  marginBottom: '10px',
  fontFamily: 'Rubik',
  width: '100%'
}));

export const ModuleItemNew = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '29px 50px 29px 50px',

  fontFamily: 'Rubik',
  width: '100%',
  ':first-of-type': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
  },
  ':last-of-type': {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px'
  }
}));

export const CustomWidthTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#666666',
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    padding: '20px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
    borderRadius: '10px'
  }
});

export const TypographyInfo = styled(Typography)(() => ({
  width: '80px',
  fontWeight: 400
}));

export const TypographyHD = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px',
  color: '#666666'
}));

export const GridScroll = styled(Grid)(() => ({
  maxHeight: '550px',
  overflowY: 'scroll',
  paddingRight: '30px',
  orderRadius: '20px',
  background:
    'var(--Gradient-for-forms, linear-gradient(94deg, rgba(255, 255, 255, 0.80) -5.21%, rgba(255, 255, 255, 0.30) 114.12%))',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)',
  '::-webkit-scrollbar': {
    width: '2px',
    border: 0
  },

  '::-webkit-scrollbar-track': {
    backgroundColor: '#C9CAD9'
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#1C9DD7',
    borderRadius: '4px',
    outline: '1px solid #1C9DD7'
  }
}));

export const GridScrollNew = styled(Grid)(() => ({
  maxHeight: '550px',
  overflowY: 'scroll',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)',
  '::-webkit-scrollbar': {
    width: '0px',
    border: 0
  },

  '::-webkit-scrollbar-track': {
    backgroundColor: '#C9CAD9'
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#1C9DD7',
    borderRadius: '4px',
    outline: '1px solid #1C9DD7'
  }
}));

export const TypographySubTit = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '17px',
  color: '#131417'
}));

export const FilterGroupButton = styled(FilterGroup)(() => ({
  '.MuiButtonGroup-root': {
    flexWrap: 'wrap'
  }
}));

export const FilterGroupRight = styled(FilterGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '375px',
  padding: '30px',
  justifyContent: 'center',
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  height: 'max-content',
  marginLeft: 'auto',
  '@media (max-width: 1440px)': {
    margin: '0 0 40px 0',
    maxWidth: '100%'
  }
}));

export const FilterGroupStyle = styled(FilterGroup)(() => ({
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    div: {
      width: '100%',
      div: {
        width: 'auto',
        '.MuiFormControl-root': {
          width: '100%'
        }
      }
    }
  }
}));

export const GridForPdf = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer'
}));

export const ButtonGradient = styled(Button)(() => ({
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  borderRadius: '30px',
  padding: '10px 24px',
  color: '#ffffff !important'
}));

export const HeadingOverlay = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '1vh',
  borderRadius: 15
}));

export const ProfileHero = styled('div')(() => ({
  overflow: 'hidden',
  width: '100%',
  height: 250,
  background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
  borderRadius: 15,
  position: 'absolute',
  top: 0,
  left: 0
}));

export const CandidateGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  columnGap: '40px',
  '@media (max-width: 1440px)': {
    flexDirection: 'column-reverse'
  }
}));

export const ModalGrid = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  zIndex: 10,
  position: 'relative'
}));

export const ModalBox = styled(Box)(() => ({
  width: '590px',
  height: '300px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  margin: 'auto',
  alignItems: 'center',
  display: 'flex',
  position: 'relative'
}));

export const ModalBoxContent = styled(Box)(() => ({
  maxWidth: '430px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  color: '#131417',
  fontFamily: 'Rubik',
  rowGap: '30px'
}));

export const TypographyTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px'
}));

export const TypographyDescription = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px'
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  columnGap: '10px'
}));

export const ButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  max-width: max-content;
  justify-content: flex-start;
  border-radius: 30px;
  overflow: hidden;
  margin: 0;
  height: max-content;

  button {
    background: linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%);
    padding: 10px 45px;

    .MuiButton-label {
      background: none;
      line-height: 17px;
    }
  }
`;

export const ModalButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '10px 0',
  width: '130px'
}));

export const GridStyle = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
}));

const GridBlocks = styled(Grid)(() => ({
  width: '48%',
  '@media (max-width: 1200px)': {
    width: '100%'
  }
}));

const CardStyle = styled(Card)(() => ({
  width: '48%',
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10.4035px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px'
}));

export const Chart = ({ children }: any) => (
  <GridBlocks>
    <CardStyle className="open-job-posts-card" style={{ padding: '30px 20px' }}>
      {children}
    </CardStyle>
  </GridBlocks>
);

export const HrDivider = styled(Divider)(() => ({
  height: 0,
  border: '0.6px solid #C9CAD9',
  margin: '15px 0 10px 0'
}));

export const TypographyTitleJobPost = styled(Typography)(() => ({
  fontWeight: 600,
  fonSize: '16px',
  color: '#562C82'
}));

export const TypographyDescriptionJobPost = styled(Typography)(() => ({
  fontWeight: 400,
  fonSize: '14px',
  color: '#131417'
}));
