import React, { useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import useLocales from '@hooks/useLocales';

interface Dataset {
  label: string;
  data: number[];
}

interface TableProps {
  setTableRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null> | undefined>
  >;
  labels: string[];
  datasets: Dataset[];
}
export const TeamCandidateTable: React.FC<TableProps> = ({
  setTableRef,
  labels,
  datasets
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (setTableRef && tableRef.current) {
      for (let i = 0; i < 1; i++) {
        setTableRef(tableRef);
      }
    }
  }, [tableRef]);

  const { t } = useLocales();

  const removeBeforeDash = (input: string) => {
    const pattern = /^[^-]+-\s+/;
    const result = input.replace(pattern, '');
    return result;
  };

  const headers = [
    'Label',
    ...datasets.map((dataset) => removeBeforeDash(dataset.label)),
    t('Average'),
    t('Total')
  ];

  // Calculate totals for each dataset (column totals)
  const columnTotals = datasets.map((dataset) =>
    dataset.data.reduce((acc, value) => acc + value, 0)
  );

  // Calculate totals for each label (row totals)
  const rowAverages = labels.map((_, rowIndex) =>
    datasets.reduce(
      (acc, dataset) => acc + dataset.data[rowIndex] / datasets.length,
      0
    )
  );

  const rowTotals = labels.map((_, rowIndex) =>
    datasets.reduce((acc, dataset) => acc + dataset.data[rowIndex], 0)
  );

  // Calculate the grand total of all values
  const grandTotal = columnTotals.reduce((acc, total) => acc + total, 0);

  return (
    <TableContainer ref={tableRef} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} align={index === 0 ? 'left' : 'center'}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Main Table Rows */}
          {labels.map((label, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell align="left">{label}</TableCell>
              {datasets.map((dataset, colIndex) => (
                <TableCell key={colIndex} align="center">
                  {dataset.data[rowIndex]}
                </TableCell>
              ))}
              <TableCell align="center" style={{ fontWeight: 'bold' }}>
                {rowAverages[rowIndex].toFixed(2)} {/* Row Average */}
              </TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>
                {rowTotals[rowIndex].toFixed(2)} {/* Row Total */}
              </TableCell>
            </TableRow>
          ))}
          {/* Totals Row */}
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 'bold' }}>
              {t('Total')}
            </TableCell>
            {columnTotals.map((total, index) => (
              <TableCell
                key={index}
                align="center"
                style={{ fontWeight: 'bold' }}
              >
                {total.toFixed(2)} {/* Column Total */}
              </TableCell>
            ))}
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {/* Average of all row averages */}
              {rowAverages.reduce((acc, avg) => acc + avg, 0).toFixed(2)}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {grandTotal.toFixed(2)} {/* Grand Total */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
