import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
// guards
// layouts
import Dashboard from 'views/dashboard/Dashboard';
import Settings from 'views/dashboard/Settings';
import CultureProfiles from 'views/dashboard/CultureProfiles';
import DataAnalytics from 'views/dashboard/DataAnalytics';
import CultureProfile from 'views/dashboard/CultureProfile';
import Templates from 'views/dashboard/Templates';
import ApplicantsOverview from 'views/dashboard/ApplicantsOverview';
import CandidateProfile from 'views/dashboard/candidateProfile/CandidateProfile';
import MatchProfile from 'views/dashboard/MatchProfile';
import CandidatesOverview from 'views/dashboard/CandidatesOverview';
import Candidates from 'views/dashboard/Candidates';
import Calendar from 'views/dashboard/candidateProfile/Calendar';
import FindCandidates from 'views/dashboard/FindCandidates';
import TalentPool from 'views/dashboard/TalentPool';
import MessengerPage from 'views/dashboard/MessengerPage';
import Messenger from 'views/dashboard/Messenger';
import JobPostsOverview from 'views/dashboard/JobPostsOverview';
import JobPost from 'views/dashboard/JobPost';
import JobPostsMostViewed from 'views/dashboard/JobPostsMostViewed';
import JobPoststoDoList from 'views/dashboard/JobPoststoDoList';
import CustomerProfile from 'views/dashboard/customers/customerProfile/CustomerProfile';
import CMS from 'views/dashboard/customers/CMS';
import SelectedCandidates from 'views/dashboard/SelectedCandidates';
import { CmsSettings } from 'views/dashboard/companySettings/cmsSettings';
import Integrations from 'views/dashboard/companySettings/Integrations';
import UserSettings from 'views/dashboard/userSettings/userSettings';
import ViewtoDoList from 'views/dashboard/ViewtoDoList';
import CampaignsOverview from 'views/dashboard/CampaignsOverview';
import CampaignMoreInfo from 'views/dashboard/CampaignMoreInfo';
import StartCampaign from 'views/dashboard/StartCampaign';
import Jobs from 'views/dashboard/Jobs';
import LoadingScreen from '../components/LoadingScreen';
import DashboardLayout from '../layouts/dashboard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_DASHBOARD } from './paths';
import Teams from '../views/dashboard/Teams';
import TeamPage from '../views/dashboard/TeamPage';
// ----------------------------------------------------------------------

const DashboardRoutes = [
  {
    // path: PATH_DASHBOARD.root,
    layout: DashboardLayout,
    guard: AuthGuard,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // GENERAL
      {
        path: PATH_DASHBOARD.showcase,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          </Suspense>
        )
      },

      // COMPANY
      {
        path: PATH_DASHBOARD.company.settings,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Settings />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.culture_profile.cultureProfiles,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CultureProfiles />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.dataAnalytics.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <DataAnalytics />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.culture_profile.cultureProfile,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CultureProfile />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.templates.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Templates />
          </Suspense>
        )
      },

      // CANDIDATES
      {
        path: PATH_DASHBOARD.candidates.applicantsOverview,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <ApplicantsOverview />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.candidateProfile,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CandidateProfile />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.matchProfile,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <MatchProfile />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.matchProfileCandidates,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CandidatesOverview />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Candidates />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.calendar.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Calendar />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.findCandidates,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <FindCandidates />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.talentPoolCandidates,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <TalentPool />
          </Suspense>
        )
      },

      {
        path: PATH_DASHBOARD.candidates.teams,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Teams />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.team,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <TeamPage />
          </Suspense>
        )
      },
      //       {
      //         path: PATH_DASHBOARD.candidates.getTeamID(':id'),
      // element: (
      //           <Suspense fallback={<LoadingScreen />}>
      //             <TalentPool />
      //           </Suspense>
      //         )
      //         component: lazy(() => import('../views/dashboard/TeamPage'))
      //       },

      {
        path: PATH_DASHBOARD.messenger.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <MessengerPage />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.chat,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Messenger messengerVariant="candidates" />
          </Suspense>
        )
      },

      // JOB POSTS
      {
        path: PATH_DASHBOARD.jobPosts.bestMatches,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPostsOverview />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.jobPosts.jobPostsOverview,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPostsOverview />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.jobPosts.jobPost,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPost />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.jobPosts.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Jobs />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.jobPosts.mostViewed,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPostsMostViewed />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.toDoList.currentToDo,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPoststoDoList />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.toDoList.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <JobPoststoDoList />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.CUSTOMERS.customerProfile,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CustomerProfile />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.CUSTOMERS.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CMS />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.candidates.candidatesOverview,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <SelectedCandidates />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.company.cmsSetting,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CmsSettings />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.company.integrations,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Integrations />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.user.root,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <UserSettings />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.jobPosts.viewtoDoList,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <ViewtoDoList />
          </Suspense>
        )
      },

      // CAMPAIGNS
      {
        path: PATH_DASHBOARD.jobPosts.campaignsOverview,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CampaignsOverview />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.campaigns.campaignMoreInfo,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <CampaignMoreInfo />
          </Suspense>
        )
      },
      {
        path: PATH_DASHBOARD.campaigns.startCampaign,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <StartCampaign />
          </Suspense>
        )
      },

      // Catch all route
      { path: '*', element: <Navigate to="/404" replace /> }
    ]
  }
];

export default DashboardRoutes;
