// ----------------------------------------------------------------------
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth/';
export const HOST_URL = `${window.location.origin}`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login/'),
  loginUnprotected: path(ROOTS_AUTH, 'login-unprotected/'),
  register: path(ROOTS_AUTH, 'register/'),
  registerUnprotected: path(ROOTS_AUTH, 'register-unprotected/'),
  resetPassword: path(ROOTS_AUTH, 'reset-password/'),
  verify: path(ROOTS_AUTH, 'verify-new-password/')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon/',
  maintenance: '/maintenance/',
  payment: '/payment/',
  page404: '/404/',
  page500: '/500/',
  denyPrivacy: '/deny-privacy/',
  acceptPrivacy: '/accept-privacy/'
};

export const ROOTS_DASHBOARD = '/';
export const DASHBOARD_ACCOUNT = 'account/';
export const DASHBOARD_COMPANY = 'company/';
export const DASHBOARD_CULTURE = 'culture/';
export const DASHBOARD_JOB_POSTS = 'jobs/';
export const DASHBOARD_CANDIDATES = 'candidates/';
export const DASHBOARD_CALENDAR = 'calendar/';
export const DASHBOARD_CAMPAIGNS = 'campaigns/';
export const DATA_ANALYTICS = 'analytics/';
export const CUSTOMERS = 'customers/';
export const MESSENGER = 'messenger/';
export const TODOLIST = 'todos/';
export const TEMPLATES = 'templates/';
export const DASHBOARD_USER = 'user/';
export const CUSTOMER_PROFILE = 'customers/';
export const CULTURE_PROFILE = 'culture-profiles/';
export const MATCH_PROFILE = 'match-profile/';
export const INTEGRATIONS = 'integrations/';
export const CALENDAR_EVENT = 'calendar/';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  showcase: `${ROOTS_DASHBOARD}dashboard/`,
  account: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}`,
    showcase: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}showcase/`
  },
  culture_profile: {
    cultureProfiles: `${ROOTS_DASHBOARD}${CULTURE_PROFILE}`,
    cultureProfile: `${ROOTS_DASHBOARD}${CULTURE_PROFILE}:id/`,
    getCultureProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${CULTURE_PROFILE}${id}/`
  },
  company: {
    root: `/${DASHBOARD_COMPANY}`,
    settings: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}account-setting/`,
    managementInsights: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}management-insights/`,
    cmsSetting: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}site-setting/`,
    integrations: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}${INTEGRATIONS}`
  },
  user: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_USER}`
  },
  candidates: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}`,
    applicantsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}#applicants/`,
    candidateProfile: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}:id/`,
    candidateProfileNotFound: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}404page/`,
    findCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}find-candidates/`,
    matchProfile: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}find-candidates/:id/`,
    matchProfileCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}candidates/:id/`,
    talentPoolCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}account/all_candidates/`,
    chat: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}:id/?chatroom=:room`,
    teams: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}#teams/`,
    team: `${ROOTS_DASHBOARD}teams/:id/`,

    getCharRoom: (room: string | number, id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${id}/?chatroom=${room}`,

    getMatchProfileCandidatesUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}candidates/${id}/`,
    getCandidateProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${id}/`,
    getMatchProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}find-candidates/${id}/`,
    candidatesOverview: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}overview-selected/`,
    getTeamID: (id: string | number) => `${ROOTS_DASHBOARD}teams/${id}`
  },
  jobPosts: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}`,
    mostViewed: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}#most-viewed/`,
    jobPostsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}#all/`,
    bestMatches: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}overview/:id/`,
    getBestMatchesJobUrl: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}overview/${id}/`,
    viewtoDoList: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}viewtodolist/`,
    currentToDoURL: (id?: any) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}current/${id}`,
    jobPost: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}:id/`,
    getJobPostUrl: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}${id}/`,
    campaignsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}#campaigns/`
  },
  campaigns: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}`,
    startCampaign: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}start/`,
    getCampaignsOverviewForJobPost: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}overview/?for_vacancy=${id}`,
    campaignMoreInfo: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}:id/`,
    getCampaignMoreInfoUrl: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}${id}/`
  },
  dataAnalytics: {
    root: `${ROOTS_DASHBOARD}${DATA_ANALYTICS}`
  },
  calendar: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_CALENDAR}`,
    event: `${ROOTS_DASHBOARD}${CALENDAR_EVENT}events/:id/`,
    getCalendarUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CALENDAR}${id}/`,
    getCalendarEventUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${CALENDAR_EVENT}events/${id}/`
  },
  CUSTOMERS: {
    root: `${ROOTS_DASHBOARD}${CUSTOMERS}`,
    customerProfile: `${ROOTS_DASHBOARD}${CUSTOMER_PROFILE}:id/`,
    getCustomerById: (id: any) => `${ROOTS_DASHBOARD}${CUSTOMER_PROFILE}${id}/`
  },
  messenger: {
    root: `${ROOTS_DASHBOARD}${MESSENGER}`
  },
  toDoList: {
    root: `${ROOTS_DASHBOARD}${TODOLIST}`,
    getmostViewedPeriod: (type?: string | number | null) =>
      `${ROOTS_DASHBOARD}${TODOLIST}?type=${type}`,
    currentToDo: `${ROOTS_DASHBOARD}${TODOLIST}:id/`
  },
  templates: {
    root: `${ROOTS_DASHBOARD}${TEMPLATES}`
  }
};

export const PATH_HOME = {
  home: '/',
  jobs: '/our-jobs/',
  job: '/our-jobs/:id/',
  getJobUrl: (slug?: string | null) => `/our-jobs/${slug}/`,
  dashboardRecruiter: PATH_DASHBOARD.showcase,
  dashboardCandidate: PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')
};
